import {
  Box,
  Button,
  Heading,
  HStack,
  Stack,
  Text,
  useBoolean,
} from "@chakra-ui/react"
import { useNavigate } from "react-router-dom"
import { PatientResponses } from "@bleu/types/endpoints/patients"
import { Document } from "@bleu/types/prisma-client"
import { BasicSealdSignInComponent } from "@bleu/front/components/doctor/BasicSealdSignInComponent"
import { LabResultsAnalyzerForm } from "@bleu/front/components/doctor/LabResultsAnalyzerForm"
import { BackButton } from "@bleu/front/components/layout/BackButton"
import { DoctorLayout } from "@bleu/front/components/layout/DoctorLayout"
import { Loader } from "@bleu/front/components/layout/Loader"
import { ConversationProvider } from "@bleu/front/components/seald/ConversationProvider"
import { SealdProvider } from "@bleu/front/components/seald/SealdProvider"
import { useFileSignedUrlQuery } from "@bleu/front/queries/files"
import { useCreateConversationMutation } from "@bleu/front/queries/seald"

type Props = {
  labResult: Document
  medicalRecord: PatientResponses.PatientMedicalRecord
}

export const LabResultsAnalyzer = ({ medicalRecord, labResult }: Props) => {
  const navigate = useNavigate()

  const { data: docSignedUrl } = useFileSignedUrlQuery(labResult.filePath)
  const [showPassword, setShowPassword] = useBoolean()

  const { mutateAsync: createConversation, isPending: isCreatingConversation } =
    useCreateConversationMutation(
      medicalRecord.id,
      "conversation",
      medicalRecord.patient,
    )

  return (
    <Stack gap={12} w="100%" h="100%">
      <HStack gap={6}>
        <BackButton
          onClick={() =>
            navigate(
              `/doctor/patients/${medicalRecord.patientId}/records/${medicalRecord.id}`,
            )
          }
        />
        <DoctorLayout.Title>
          {medicalRecord.patient.fullName}
        </DoctorLayout.Title>
      </HStack>
      <HStack gap={5} align="flex-start" h="calc(100vh - 224px)">
        <Box w="60%" h="100%">
          {!docSignedUrl ? (
            <Loader />
          ) : (
            <iframe
              src={docSignedUrl.signedUrl}
              style={{
                width: "100%",
                height: "100%",
                borderRadius: 8,
              }}
            />
          )}
        </Box>
        <Box w="40%">
          <DoctorLayout.Body>
            <Stack gap={5}>
              <Heading>Commenter un examen</Heading>
              {labResult.password && (
                <Text
                  onMouseEnter={setShowPassword.on}
                  onMouseLeave={setShowPassword.off}
                >
                  Mot de passe:{" "}
                  <b>
                    {showPassword ? labResult.password : "(passez la souris)"}
                  </b>
                </Text>
              )}
              {!medicalRecord.conversationId ? (
                <SealdProvider>
                  <Button
                    onClick={() => createConversation()}
                    isLoading={isCreatingConversation}
                  >
                    Ouvrir une session chiffrée
                  </Button>
                </SealdProvider>
              ) : (
                <ConversationProvider
                  signInComponent={BasicSealdSignInComponent}
                  conversationId={medicalRecord.conversationId}
                >
                  {({ conversation, sealdSession }) => (
                    <LabResultsAnalyzerForm
                      patientId={medicalRecord.patientId}
                      medicalRecordId={medicalRecord.id}
                      labResultId={labResult.id}
                      conversationId={conversation.id}
                      sealdSession={sealdSession}
                    />
                  )}
                </ConversationProvider>
              )}
            </Stack>
          </DoctorLayout.Body>
        </Box>
      </HStack>
    </Stack>
  )
}
