import { useParams } from "react-router-dom"
import { PatientFile } from "@bleu/front/components/doctor/PatientFile"
import { QueryWrapper } from "@bleu/front/components/layout/QueryWrapper"
import { usePatientQuery } from "@bleu/front/queries/patients"

export const PatientFileLayout = () => {
  const params = useParams()
  const patientQuery = usePatientQuery(params.patientId as string)
  return (
    <QueryWrapper query={patientQuery}>
      {({ data }) => <PatientFile patient={data} />}
    </QueryWrapper>
  )
}
