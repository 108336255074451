import { colors } from "@bleu/front/assets/theme/colors"
import { VSpacer } from "@bleu/front/components/layout/Spacer"
import { useFunnelStore, useVerticalsStore } from "@bleu/front/lib/stores"
import { useAuthState } from "@bleu/front/queries/auth"
import { VerticalName } from "@bleu/shared/verticals"
import {
  Box,
  Button,
  Heading,
  Highlight,
  HStack,
  Text,
  VStack,
} from "@chakra-ui/react"
import { lazy, Suspense } from "react"
import { useNavigate } from "react-router-dom"
import { useResetStores } from "../components/funnelSteps/hooks/useResetStores"

// Lazy load non-critical components
const LazyVerticalsCards = lazy(() =>
  import("@bleu/front/components/patientSpace/VerticalsCards").then(
    (module) => ({
      default: module.VerticalsCards,
    }),
  ),
)
const LazyStepsCardsList = lazy(() =>
  import("@bleu/front/components/patientSpace/StepCardsList").then(
    (module) => ({
      default: module.StepsCardsList,
    }),
  ),
)
const LazyPatientMedicalRecordsHistory = lazy(
  () => import("./PatientMedicalRecordsHistory"),
)

export const PatientHomePage = () => {
  const { isSignedIn, isLoading, user } = useAuthState()
  const navigate = useNavigate()
  const { setSelectedVerticals } = useVerticalsStore()
  const { setSelectedVerticalsAndDepistages } = useFunnelStore()
  useResetStores()

  const handleNewScreeningClick = () => {
    setSelectedVerticals([VerticalName.GENERAL])
    setSelectedVerticalsAndDepistages(VerticalName.GENERAL)
    navigate("/questionnaire?step=depistagesSelection")
  }

  return (
    <Box>
      <HStack justify="space-between">
        <Heading size="3xl">
          {!isLoading && (
            <Highlight
              query={isSignedIn ? user.firstName : "Bleu"}
              styles={{ color: colors.blue[400] }}
            >
              {isSignedIn
                ? `Bienvenue, ${user.firstName} !`
                : "Bienvenue sur Bleu"}
            </Highlight>
          )}
        </Heading>
      </HStack>
      <VSpacer size={3} />

      {isSignedIn && (
        <>
          <Text color="navy.300">
            Dans votre espace patient : ordonnances, messagerie sécurisée et
            informations de compte.
          </Text>
          <VSpacer size={8} />
          <Heading size="2xl">Mes ordonnances</Heading>
          <VSpacer size={6} />
          <Suspense fallback={<div>Chargement des ordonnances...</div>}>
            <LazyPatientMedicalRecordsHistory />
          </Suspense>
        </>
      )}

      <VSpacer size={8} />
      <Heading size="2xl">Dépistages disponibles</Heading>
      <VSpacer size={6} />
      <Suspense fallback={<div>Chargement des dépistages...</div>}>
        <LazyVerticalsCards />
      </Suspense>
      <VSpacer size={8} />
      <Heading size="2xl">Comment ça marche</Heading>
      <VSpacer size={6} />
      <Suspense fallback={<div>Chargement des étapes...</div>}>
        <LazyStepsCardsList />
      </Suspense>
      <VStack
        display={["flex", "none"]}
        alignItems="stretch"
        position="sticky"
        bottom={10}
      >
        <VSpacer size={20} />
        <Button onClick={handleNewScreeningClick}>Nouveau dépistage</Button>
      </VStack>
    </Box>
  )
}
