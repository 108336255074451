import { Text, Stack } from "@chakra-ui/react"
import { DoctorResponses } from "@bleu/types/endpoints/doctor"

export const StatBlock = ({ label, value }: DoctorResponses.StatBlock) => (
  <Stack w={130} bgColor="white" borderRadius={8} p={3} gap={2}>
    <Text size="4xl" color="blue.400">
      {value}
    </Text>
    <Text fontSize="2xs" fontWeight="bold">
      {label}
    </Text>
  </Stack>
)
