import { frFR } from "@clerk/localizations"
import { LocalizationResource } from "@clerk/types"
import { colors } from "./colors"
import { FONT_FAMILY } from "./index"

export const CLERK_THEME = {
  layout: {
    socialButtonsPlacement: "bottom",
  },
  variables: {
    colorPrimary: colors.blue[400],
    colorDanger: colors.red[400],
    colorSuccess: colors.green[400],
    colorWarning: colors.orange[400],
    colorText: colors.black,
    colorTextOnPrimaryBackground: colors.white,
    colorTextSecondary: colors.navy[300],
    colorInputText: colors.navy[400],
    fontFamily: FONT_FAMILY,
  },
} as const

export const CLERK_COPY: LocalizationResource = {
  ...frFR,
  signUp: {
    ...frFR.signUp,
    start: {
      ...(frFR.signUp?.start ?? {}),
      title: "Authentification sécurisée",
      subtitle: "Créez un compte pour accéder à Bleu.care",
    },
  },
  signIn: {
    ...frFR.signIn,
    start: {
      ...(frFR.signIn?.start ?? {}),
      title: "Authentification sécurisée",
      subtitle: "Connectez-vous pour accéder à Bleu.care",
    },
    password: {
      ...(frFR.signIn?.password ?? {}),
      subtitle: "pour accéder à Bleu.care",
    },
    forgotPassword: {
      ...(frFR.signIn?.forgotPassword ?? {}),
      title: "Réinitialiser votre mot de passe",
      subtitle_email: "Entrez le code envoyé à votre adresse e-mail",
    },
    alternativeMethods: {
      ...(frFR.signIn?.alternativeMethods ?? {}),
      subtitle:
        "Vous rencontrez des problèmes ? Vous pouvez utiliser l'un de ces moyens pour vous connecter.",
    },
    passwordPwned: {
      title:
        "Ce mot de passe a déjà été repéré dans des fuites de données, merci d'en utiliser un autre",
    },
  },
  unstable__errors: {
    ...frFR.unstable__errors,
    form_identifier_not_found: "Aucun compte trouvé avec ces identifiants",
    form_password_incorrect: "Mot de passe incorrect",
    form_param_format_invalid__phone_number:
      "Format de numéro de téléphone invalide, essayez sans le zéro au début",
    form_code_incorrect: "Code incorrect",
    not_allowed_access: "Accès non autorisé",
    form_identifier_exists__phone_number:
      "Ce numéro de téléphone est déjà associé à un compte",
    form_param_nil: "Ce champ est requis",
  },
}
