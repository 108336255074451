import _ from "lodash"
import { useCallback, useMemo } from "react"
import { useNavigate } from "react-router-dom"
import { VerticalName } from "@bleu/shared/verticals"
import { useFunnelStore, useVerticalsStore } from "@bleu/front/lib/stores"
import { logger } from "@bleu/front/utils/logger"
import { Steps } from "./index"

/**
 * Custom hook for managing step navigation within the questionnaire funnel.
 *
 * @param searchParams - The URL search parameters containing the current step and sub-step.
 * @returns An object containing handlers for navigating to the next and previous steps,
 *          as well as the current step and sub-step parameters.
 */
export const useStepNavigation = (searchParams: URLSearchParams) => {
  const { setLastCompletedStep, setCurrentStep, currentStep } = useFunnelStore()
  const navigate = useNavigate()
  const { selectedVerticals } = useVerticalsStore()

  /**
   * Retrieves the current step parameter from the URL search parameters.
   */
  const stepParam = useMemo(() => {
    const param = searchParams.get("step")
    return param
      ? _.findKey(Steps, (value) => value === parseInt(param))
      : undefined
  }, [searchParams])

  /**
   * Retrieves the current sub-step parameter from the URL search parameters.
   */
  const subStepParam = useMemo(() => {
    return parseInt(searchParams.get("subStep") || "0")
  }, [searchParams])

  /**
   * Handles navigation to the next step or sub-step.
   *
   * @param totalSubSteps - The total number of sub-steps in the current step, if any.
   */
  const handleNext = useCallback(
    (totalSubSteps?: number) => {
      if (totalSubSteps && subStepParam < totalSubSteps - 1) {
        // Move to next sub-step
        const newSearchParams = new URLSearchParams(searchParams)
        newSearchParams.set("subStep", (subStepParam + 1).toString())
        navigate(`/questionnaire?${newSearchParams.toString()}`)
      } else {
        // Move to next main step
        let nextStep = currentStep + 1
        if (
          currentStep === Steps.profile &&
          selectedVerticals[0] !== VerticalName.GENERAL
        ) {
          nextStep = Steps.questionnaires
        }
        setLastCompletedStep(currentStep)
        setCurrentStep(nextStep)
        const newSearchParams = new URLSearchParams(searchParams)
        newSearchParams.set("step", nextStep.toString())
        newSearchParams.delete("subStep")
        navigate(`/questionnaire?${newSearchParams.toString()}`)
      }
    },
    [
      navigate,
      searchParams,
      setLastCompletedStep,
      setCurrentStep,
      currentStep,
      subStepParam,
      selectedVerticals,
    ],
  )

  /**
   * Handles navigation to the previous step or sub-step.
   */
  const goToPreviousStep = useCallback(() => {
    if (subStepParam > 0) {
      // Move to previous sub-step
      const newSearchParams = new URLSearchParams(searchParams)
      newSearchParams.set("subStep", (subStepParam - 1).toString())
      navigate(`/questionnaire?${newSearchParams.toString()}`)
    } else if (currentStep > Steps.start) {
      let previousStep = currentStep - 1
      if (
        currentStep === Steps.questionnaires &&
        selectedVerticals[0] !== VerticalName.GENERAL
      ) {
        previousStep = Steps.profile
      }
      logger.info(
        `Navigating to previous step: ${Steps[previousStep as unknown as keyof typeof Steps]}`,
      )
      setCurrentStep(previousStep)
      const newSearchParams = new URLSearchParams(searchParams)
      newSearchParams.set("step", previousStep.toString())
      newSearchParams.delete("subStep")
      navigate(`/questionnaire?${newSearchParams.toString()}`)
    } else {
      logger.warn("Already at the first step, cannot go back further")
      navigate("/")
    }
  }, [
    navigate,
    searchParams,
    setCurrentStep,
    currentStep,
    subStepParam,
    selectedVerticals,
  ])

  return { handleNext, goToPreviousStep, stepParam, subStepParam }
}
