import { useMutation, useQuery } from "@tanstack/react-query"
import { FileResponses } from "@bleu/types/endpoints/files"
import { apiClient } from "@bleu/front/lib/apiClient"
import { downloadFile } from "@bleu/front/utils/files"

export type DownloadFileInput = { filePath: string; filename: string }

export const useFileSignedUrlQuery = (filePath: string) => {
  return useQuery({
    queryKey: ["files", filePath],
    queryFn: async () => {
      const response = await apiClient.get<FileResponses.SignedUrl>(
        `files/${filePath}`,
      )
      return response.data
    },
  })
}

export const useDownloadFileMutation = () => {
  return useMutation({
    mutationFn: async ({ filename, filePath }: DownloadFileInput) => {
      const response = await apiClient.post<Blob>(
        "files/download",
        { filePath },
        { responseType: "blob" },
      )
      const file = response.data

      downloadFile(file, filename)

      return filePath
    },
  })
}
