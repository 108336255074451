import { useQuery } from "@tanstack/react-query"
import { PatientResponses } from "@bleu/types/endpoints/patients"
import { apiClient } from "@bleu/front/lib/apiClient"

export const usePatientQuery = (patientId: string) => {
  return useQuery({
    queryKey: ["patients", patientId],
    queryFn: async () => {
      const response = await apiClient.get<PatientResponses.Patient>(
        `/patients/${patientId}`,
      )
      return response.data
    },
  })
}

export const usePatientMedicalRecordsQuery = (
  patientId: string,
  recordId: string,
) => {
  return useQuery({
    queryKey: ["medical-records", recordId],
    queryFn: async () => {
      const response =
        await apiClient.get<PatientResponses.PatientMedicalRecord>(
          `/patients/${patientId}/medical-records/${recordId}`,
        )
      return response.data
    },
  })
}
