import { Box } from "@chakra-ui/react"
import type { PropsWithChildren } from "react"
import { colors } from "@bleu/front/assets/theme/colors"
import { ChatInterface } from "@bleu/front/components/ChatInterface"

/**
 * PlaceholderChatInterface component
 * Displays a placeholder chat interface with a blurred overlay
 */
export const PlaceholderChatInterface: React.FC<PropsWithChildren> = ({
  children,
}) => (
  <Box
    h="70vh"
    position="relative"
    borderTopWidth={1}
    borderTopColor="blue.200"
  >
    <ChatInterface
      messages={[
        {
          authorUserId: "1",
          authorFirstName: "John",
          message:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris sollicitudin, tortor ac volutpat efficitur, metus felis dignissim ante, non viverra risus arcu ac magna. Nam ut dignissim risus, ut ullamcorper nisl. Curabitur vel ex nec nulla euismod ",
          date: new Date(),
        },
      ]}
      onPostMessage={async () => {}} // Changed to async function
      isPostingMessage={false}
    />
    <Box
      position="absolute"
      bg={`${colors.white}70`}
      backdropFilter="blur(2px)"
      top={0}
      bottom={0}
      right={-4}
      pr={4}
      left={0}
      px={6}
    >
      {children}
    </Box>
  </Box>
)
