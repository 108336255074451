import { Button, Center, VStack } from "@chakra-ui/react"
import { IconLockOpen } from "@tabler/icons-react"
import { ComponentType, ReactNode, useMemo, memo } from "react"
import { PlaceholderChatInterface } from "@bleu/front/components/PlaceholderChat"
import { useUser } from "@bleu/front/components/auth/UserContext"
import { FullPageLoader } from "@bleu/front/components/layout/FullPageLoader"
import { SealdChallengeForm } from "@bleu/front/components/seald/SealdChallengeForm"
import {
  useSealdIdentityQuery,
  useSealdSignInMutation,
} from "@bleu/front/queries/seald"

export type SealdSignInComponentProps = {
  isSigningIn: boolean
  onSignIn: () => void
}

type Props = {
  children: ReactNode
  signInComponent?: ComponentType<SealdSignInComponentProps>
}

export const SealdProvider: React.FC<Props> = memo(
  ({ children, signInComponent: SignIn }) => {
    const user = useUser()

    const { data: signedInSealdId, isPending: isLoadingSealdIdentity } =
      useSealdIdentityQuery()
    const {
      data: signInResult,
      mutateAsync: handleSealdSignIn,
      isPending: isSigningIn,
    } = useSealdSignInMutation()

    const isSignedIn = useMemo(
      () => !!signedInSealdId && signedInSealdId === user.sealdId,
      [signedInSealdId, user.sealdId],
    )

    const handleSignIn = () => handleSealdSignIn(undefined)

    if (isLoadingSealdIdentity) return <FullPageLoader />
    if (isSignedIn) return <>{children}</>

    if (signInResult?.tmrChallenge.mustAuthenticate) {
      return (
        <Center w="100%" h="100%">
          <SealdChallengeForm
            userId={user.id}
            email={user.emailAddress}
            sealdId={signInResult.sealdId}
            twoManRuleChallenge={signInResult.tmrChallenge}
            showChatPlaceHolder={!SignIn}
          />
        </Center>
      )
    }

    if (SignIn) {
      return <SignIn isSigningIn={isSigningIn} onSignIn={handleSignIn} />
    }

    return (
      <PlaceholderChatInterface>
        <VStack justifyContent="space-around" h="100%">
          <Button
            isLoading={isSigningIn}
            onClick={handleSignIn}
            leftIcon={<IconLockOpen />}
          >
            Déverrouiller le contenu
          </Button>
        </VStack>
      </PlaceholderChatInterface>
    )
  },
)

SealdProvider.displayName = "SealdProvider"
