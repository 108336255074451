import { Input } from "@chakra-ui/react"
import type { QuestionProps } from "@bleu/front/components/forms/types"
import { QuestionWrapper } from "./QuestionWrapper"

interface FreeTextQuestionProps extends QuestionProps<string> {
  title: string
  subtitle?: string
}

export const FreeTextQuestion = ({
  title,
  subtitle,
  onChange,
  ...props
}: FreeTextQuestionProps) => {
  return (
    <QuestionWrapper title={title} subtitle={subtitle}>
      <Input
        borderRadius={4}
        borderColor="blue.300"
        onChange={(e) => onChange(e.target.value)}
        width="100%"
        {...props}
      />
    </QuestionWrapper>
  )
}
