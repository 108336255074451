import { Hide, Show } from "@chakra-ui/react"
import { DesktopUserMenu } from "@bleu/front/components/auth/UserMenu/DesktopUserMenu"
import { MobileUserMenu } from "@bleu/front/components/auth/UserMenu/MobileUserMenu"

export const UserMenu = () => {
  return (
    <>
      <Show above="md">
        <DesktopUserMenu />
      </Show>
      <Hide above="md">
        <MobileUserMenu />
      </Hide>
    </>
  )
}
