import {
  Text,
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  AlertStatus,
  HStack,
  Icon,
  Stack,
  Link,
} from "@chakra-ui/react"
import {
  IconAlertHexagon,
  IconCheck,
  IconCircleX,
  IconInfoCircle,
  IconLoader,
  type IconProps,
} from "@tabler/icons-react"
import { useMemo, type ForwardRefExoticComponent } from "react"
import { ColorHue } from "@bleu/front/assets/theme/colors"

interface InfoBoxStatusProps {
  color: ColorHue
  bgColor: ColorHue
  icon: ForwardRefExoticComponent<Omit<IconProps, "ref">>
}

const infoBoxStatusScheme: Record<AlertStatus, InfoBoxStatusProps> = {
  info: {
    color: "blue.400",
    bgColor: "blue.200",
    icon: IconInfoCircle,
  },
  loading: {
    color: "blue.400",
    bgColor: "blue.200",
    icon: IconLoader,
  },
  warning: {
    color: "orange.400",
    bgColor: "orange.200",
    icon: IconAlertHexagon,
  },
  success: {
    color: "green.400",
    bgColor: "green.200",
    icon: IconCheck,
  },
  error: {
    color: "red.400",
    bgColor: "red.200",
    icon: IconCircleX,
  },
}

type Props = {
  title: string
  message: string
  sources?: { title: string; url: string }[]
  status?: AlertStatus
  icon?: ForwardRefExoticComponent<Omit<IconProps, "ref">>
  isCollapsible?: boolean
  isOpen?: boolean
}

export const InfoBox = ({
  title,
  message,
  sources,
  icon,
  status = "info",
  isCollapsible = false,
  isOpen = false,
}: Props) => {
  const {
    color,
    bgColor,
    icon: defaultIcon,
  } = useMemo(() => infoBoxStatusScheme[status], [status])

  return (
    // This forces the accordion
    // to stay opened if it's not collapsible                 👇
    <Accordion
      allowToggle={true}
      defaultIndex={!isCollapsible || (isCollapsible && isOpen) ? 0 : undefined}
    >
      <AccordionItem bg={bgColor} px={4} py={3} borderRadius={8}>
        <AccordionButton
          cursor={isCollapsible ? "pointer" : "default"}
          padding={0}
          justifyContent="space-between"
          {...(!isCollapsible && { _hover: { cursor: "default" } })}
        >
          <HStack>
            <Icon as={icon ?? defaultIcon} boxSize={6} color={color} />
            <Text fontWeight={600}>{title}</Text>
          </HStack>
          {isCollapsible && <AccordionIcon />}
        </AccordionButton>
        <AccordionPanel padding={0} marginTop={3} whiteSpace="pre-line">
          <Stack gap={3}>
            <Text fontSize="sm" whiteSpace="pre-wrap">
              {message}
            </Text>
            {sources && (
              <Text fontSize="sm">
                Sources:{" "}
                {sources.map((source, index) => (
                  <span key={source.url}>
                    <Link
                      href={source.url}
                      color="black"
                      fontWeight="normal"
                      target="_blank"
                    >
                      {source.title}
                    </Link>
                    {index < sources.length - 1 && ", "}
                  </span>
                ))}
              </Text>
            )}
          </Stack>
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  )
}
