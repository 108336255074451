import {
  useDisclosure,
  Skeleton,
  IconButton,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  HStack,
  Divider,
  DrawerFooter,
  Text,
} from "@chakra-ui/react"
import { useClerk } from "@clerk/clerk-react"
import * as Sentry from "@sentry/react"
import {
  IconBrandGoogleHome,
  IconMenu2,
  IconHeart,
  IconUser,
  IconStethoscope,
  IconUserPlus,
  IconLogout,
} from "@tabler/icons-react"
import { Link } from "react-router-dom"
import { UserRole } from "@bleu/types/user"
import { checkUserAuthorization } from "@bleu/shared/utils/auth"
import { colors } from "@bleu/front/assets/theme/colors"
import { Logo } from "@bleu/front/components/layout/Logo"
import { VSpacer } from "@bleu/front/components/layout/Spacer"
import { initiateSealdClient, sealdClient } from "@bleu/front/lib/seald"
import { useAuthState } from "@bleu/front/queries/auth"

export const MobileUserMenu = () => {
  const { signOut } = useClerk()
  const { user, isSignedIn, isLoading } = useAuthState()
  const { isOpen, onOpen, onClose } = useDisclosure()

  const handleLogout = async () => {
    if (!sealdClient && user?.sealdDatabaseKey) {
      await initiateSealdClient(user.sealdDatabaseKey)
    }
    if (sealdClient) {
      await sealdClient.dropDatabase()
    } else {
      Sentry.captureException(new Error("Seald client not found"))
    }
    await signOut()
  }

  if (isLoading)
    return (
      <Skeleton w={150} height={10} startColor="blue.100" endColor="blue.200" />
    )

  return (
    <HStack>
      {user && checkUserAuthorization(user, UserRole.DOCTOR, {}) && (
        <IconButton
          css={{ padding: "8px !important" }}
          w={12}
          h={12}
          as={Link}
          to="/doctor"
          borderRadius={8}
          icon={<IconBrandGoogleHome size={24} color={colors.blue[400]} />}
          bg="blue.200"
          aria-label="Espace médecin"
        />
      )}
      <IconButton
        css={{ padding: "8px !important" }}
        w={12}
        h={12}
        borderRadius={8}
        icon={<IconMenu2 size={24} color={colors.blue[400]} />}
        bg="blue.200"
        aria-label="Menu"
        onClick={onOpen}
      />
      <Drawer isOpen={isOpen} onClose={onClose} size="xs">
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>
            <VSpacer size={3} />
            <Logo />
          </DrawerHeader>

          <DrawerBody p={0}>
            <VSpacer size={5} />
            {isSignedIn ? (
              <>
                <HStack as={Link} to="/" py={2} px={4}>
                  <IconHeart size={20} />
                  <Text>Mon espace patient</Text>
                </HStack>
                <Divider />
                <HStack as={Link} to="/account/preferences" py={2} px={4}>
                  <IconUser size={20} />
                  <Text>Mon compte</Text>
                </HStack>
                <Divider />
                {[UserRole.DOCTOR, UserRole.ADMIN].includes(user.role) && (
                  <HStack as={Link} to="/doctor" py={2} px={4}>
                    <IconStethoscope size={20} />
                    <Text>Espace docteur</Text>
                  </HStack>
                )}
              </>
            ) : (
              <>
                <HStack as={Link} to="/sign-in" py={2} px={4}>
                  <IconUser size={20} />
                  <Text>Se connecter</Text>
                </HStack>
                <Divider />
                <HStack as={Link} to="/sign-up" py={2} px={4}>
                  <IconUserPlus size={20} />
                  <Text>S&apos;inscrire</Text>
                </HStack>
              </>
            )}
          </DrawerBody>

          {isSignedIn && (
            <DrawerFooter justifyContent="center">
              <HStack
                onClick={handleLogout}
                p={2}
                _hover={{ cursor: "pointer", bg: "blue.200" }}
              >
                <IconLogout size={20} color="red" />
                <Text color="red">Se déconnecter</Text>
              </HStack>
            </DrawerFooter>
          )}
        </DrawerContent>
      </Drawer>
    </HStack>
  )
}
