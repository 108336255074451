import { apiClient } from "@bleu/front/lib/apiClient"
import {
  useDepistagesStore,
  useFunnelStore,
  useQuestionnaireStore,
  useVerticalsStore,
} from "@bleu/front/lib/stores"
import { logger } from "@bleu/front/utils/logger"
import { gtmTrack, useAmplitudeTrack } from "@bleu/front/utils/tracking"
import { Box, Button, Heading, Text, VStack } from "@chakra-ui/react"
import { XCircleIcon } from "@heroicons/react/24/solid"
import { useMutation } from "@tanstack/react-query"
import { useEffect } from "react"
import { useNavigate, useSearchParams } from "react-router-dom"

export function PaymentFailed() {
  const [searchParams] = useSearchParams()
  const { selectedVerticals } = useVerticalsStore()
  const navigate = useNavigate()
  const { track } = useAmplitudeTrack()
  const medicalRecordCode = searchParams.get("r")

  useEffect(() => {
    const error = searchParams.get("error")
    if (error) {
      logger.warn("Payment failed, tracking event", {
        error,
        medicalRecordCode,
      })
      track("PAYMENT_FAIL", { error, medicalRecordCode })
    } else {
      logger.warn("No error parameter found in search params")
    }
  }, [searchParams, medicalRecordCode, track])

  const { mutate: retryPayment, isPending } = useMutation({
    mutationFn: async () => {
      logger.info("Retrying payment, creating new checkout session")

      if (!medicalRecordCode || selectedVerticals.length === 0) {
        throw new Error("Missing medicalRecordCode or vertical in store")
      }

      const response = await apiClient.post<{ url: string }>(
        "/stripe/create-checkout-session",
        { medicalRecordCode, vertical: selectedVerticals[0] },
      )
      return response.data
    },
    onSuccess: (data) => {
      logger.info("New checkout session created, redirecting")
      window.location.href = data.url
    },
    onError: (error) => {
      logger.error("Error creating new checkout session", { error })
      track("PAYMENT_FAIL", { error })
    },
  })

  function handleRetry() {
    logger.info("User initiated payment retry")
    retryPayment()
  }

  function handleCancel() {
    logger.info("User cancelled payment")
    useFunnelStore.getState().reset()
    useQuestionnaireStore.getState().reset()
    useVerticalsStore.getState().reset()
    useDepistagesStore.getState().reset()
    navigate("/")
  }

  logger.info("Rendering PaymentFailed component content")
  return (
    <Box
      className="flex flex-col justify-center items-center"
      minHeight="inherit"
      width="100%"
    >
      <Box
        maxWidth="600px"
        width="100%"
        margin="auto"
        padding={8}
        bg="white"
        rounded="md"
        border="1px solid"
        borderColor="gray.200"
      >
        <VStack spacing={8} align="center">
          <XCircleIcon className="h-24 w-24 text-red-500" />
          <Heading as="h1" size="xl" textAlign="center">
            Échec du paiement
          </Heading>
          <VStack spacing={4} align="center">
            <Text fontSize="lg" textAlign="center">
              Nous sommes désolés, mais votre paiement n&apos;a pas pu être
              traité.
            </Text>
            <Text fontSize="lg" textAlign="center">
              Veuillez réessayer ou contacter notre support si le problème
              persiste.
            </Text>
          </VStack>
          <Button
            onClick={handleRetry}
            colorScheme="blue"
            size="lg"
            isLoading={isPending}
            loadingText="Préparation..."
          >
            Réessayer le paiement
          </Button>
          <Button bg="gray.400" colorScheme="gray" onClick={handleCancel}>
            Annuler
          </Button>
        </VStack>
      </Box>
    </Box>
  )
}
