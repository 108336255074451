import {
  Icon,
  Stack,
  Table,
  TableContainer,
  Tag,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Text,
  Button,
  useClipboard,
  Tooltip,
} from "@chakra-ui/react"
import { IconChevronRight, IconCopy, IconCheck } from "@tabler/icons-react"
import dayjs from "dayjs"
import _ from "lodash"
import { useNavigate } from "react-router-dom"
import { MedicalRecordResponses } from "@bleu/types/endpoints/index"
import {
  DepistageName,
  getVerticalNameForDepistageName,
} from "@bleu/shared/depistages/index"
import { MEDICAL_RECORD_STATUSES } from "@bleu/shared/medicalRecords/statuses"
import { VerticalsLongNames } from "@bleu/shared/verticals/index"

type Props = {
  medicalRecords: MedicalRecordResponses.MedicalRecord[]
}

export const MedicalRecordsTable = ({ medicalRecords }: Props) => {
  const navigate = useNavigate()

  return (
    <TableContainer>
      <Table variant="rounded">
        <Thead>
          <Tr>
            <Th>Nom</Th>
            <Th>ID</Th>
            <Th>Âge</Th>
            <Th>Genre</Th>
            <Th>Type</Th>
            <Th>Date</Th>
            <Th>Statut</Th>
            <Th />
          </Tr>
        </Thead>
        <Tbody>
          {medicalRecords.map((record) => {
            const verticalNames = _.uniq(
              record.questionnaireAnswer.prescribedDepistages.map(
                (depistage) =>
                  getVerticalNameForDepistageName(depistage as DepistageName), // TODO: type it better
              ),
            )

            return (
              <Tr
                key={record.code}
                cursor="pointer"
                onClick={() =>
                  navigate(
                    `/doctor/patients/${record.patientId}/records/${record.id}`,
                  )
                }
              >
                <Td>{record.patient.fullName}</Td>
                <Td>
                  <CopyableId id={record.code} />
                </Td>
                <Td>{record.profile.age} ans</Td>
                <Td>{record.profile.biologicalSex}</Td>
                <Td>
                  {verticalNames.map((vertical) => (
                    <div key={vertical}>{VerticalsLongNames[vertical]}</div>
                  ))}
                </Td>
                <Td>{dayjs(record.createdAt).format("DD/MM/YYYY")}</Td>
                <Td>
                  <Tag variant="solid" colorScheme="blue">
                    {MEDICAL_RECORD_STATUSES[record.status].label}
                  </Tag>
                </Td>
                <Td>
                  <Stack>
                    <Icon as={IconChevronRight} color="blue.400" boxSize={6} />
                  </Stack>
                </Td>
              </Tr>
            )
          })}
        </Tbody>
      </Table>
    </TableContainer>
  )
}

interface CopyableIdProps {
  id: string
}

const CopyableId: React.FC<CopyableIdProps> = ({ id }) => {
  const { hasCopied, onCopy } = useClipboard(id)

  return (
    <Tooltip label={hasCopied ? "Copied!" : "Click to copy"} placement="top">
      <Button
        size="sm"
        variant="ghost"
        p={0}
        onClick={(e) => {
          e.stopPropagation()
          onCopy()
        }}
        leftIcon={hasCopied ? <IconCheck size={16} /> : <IconCopy size={16} />}
      >
        <Text fontSize="xs" color="gray.500">
          {id}
        </Text>
      </Button>
    </Tooltip>
  )
}
