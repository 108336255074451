import {
  Box,
  Button,
  Tab,
  TabList,
  TabPanels,
  Tabs,
  VStack,
  useBreakpointValue,
  type ButtonProps,
} from "@chakra-ui/react"
import { useCallback, useMemo } from "react"
import { Outlet, useLocation, useNavigate } from "react-router-dom"
import { BackButton } from "@bleu/front/components/layout/BackButton"

const ACCOUNT_PAGES = [
  {
    tabName: "Mon compte",
    href: "preferences",
  },
  {
    tabName: "Factures",
    href: "invoices",
  },
] as const

const AccountLayout = () => {
  const location = useLocation()
  const navigate = useNavigate()

  const currentTabIndex = useMemo(() => {
    const tabIndex = ACCOUNT_PAGES.findIndex(({ href }) =>
      location.pathname.includes(href),
    )
    return tabIndex >= 0 ? tabIndex : 0
  }, [location.pathname])

  const handleTabChange = useCallback(
    (tabIndex: number) => {
      const tab = ACCOUNT_PAGES[tabIndex]
      if (tab) {
        navigate(tab.href)
      }
    },
    [navigate],
  )

  const isFitted = useBreakpointValue({ base: true, md: false })
  return (
    <VStack h="100%" px={[5, 20]}>
      <Tabs
        onChange={handleTabChange}
        defaultIndex={currentTabIndex}
        w={["100%", 450]}
        isFitted={isFitted}
        flexGrow={1}
        display="flex"
        flexDir="column"
      >
        <TabList>
          {ACCOUNT_PAGES.map(({ tabName }) => (
            <Tab key={tabName}>{tabName}</Tab>
          ))}
        </TabList>
        <TabPanels py={4}>
          <Outlet />
        </TabPanels>
      </Tabs>
    </VStack>
  )
}

const AccountLayoutCTA = ({ children, ...props }: ButtonProps) => (
  <Box position={["fixed", "absolute"]} bottom={10} left={0} p={6} w="100%">
    <Button {...props} w="100%">
      {children}
    </Button>
  </Box>
)

AccountLayout.CTA = AccountLayoutCTA
AccountLayout.BackButton = BackButton

export { AccountLayout }
