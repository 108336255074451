/* eslint-disable react-hooks/exhaustive-deps */
import { Container, Image, Text, VStack } from "@chakra-ui/react"
import _ from "lodash"
import { useCallback, useEffect, useMemo } from "react"
import { type DepistageName } from "@bleu/shared/depistages"
import { computeAllPrescriptions } from "@bleu/shared/prescriptions"
import { PrescriptionType } from "@bleu/shared/prescriptions/types"
import {
  type AllQuestionnairesAnswers,
  type ProfileData,
} from "@bleu/shared/questionnaires"
import { InfoBox } from "@bleu/front/components/InfoBox"
import { PrescriptionRecapItem } from "@bleu/front/components/funnelSteps/PrescriptionRecapItem"
import { VSpacer } from "@bleu/front/components/layout/Spacer"
import { useQuestionnaireLayout } from "@bleu/front/components/layout/useQuestionnaireLayout"
import { useFunnelStore } from "@bleu/front/lib/stores"
import { useQuestionnaireLayoutStore } from "@bleu/front/lib/stores/questionnaireLayoutStore"
import { logger } from "@bleu/front/utils/logger"

interface Props {
  depistagesNames: DepistageName[]
  allAnswers: AllQuestionnairesAnswers
  profile: ProfileData
  onSubmit: () => void
  onRestart: () => void
}

export const PrescriptionRecap = ({
  depistagesNames,
  allAnswers,
  profile,
  onSubmit,
  onRestart,
}: Props) => {
  const { setHeader, setTitle, setCta, setShowBackButton } =
    useQuestionnaireLayoutStore()
  const { setPrescribedDepistages } = useFunnelStore()

  const prescriptionRecap = useMemo(() => {
    const allPrescriptions = computeAllPrescriptions(
      depistagesNames,
      allAnswers,
      profile!,
    )

    const hasOnlyAddressing = Object.values(allPrescriptions)
      .flat()
      .every(
        (p) => p.prescriptionType === PrescriptionType.PRESCRIPTION_ADDRESSING,
      )

    return _.mapValues(allPrescriptions, (prescriptions) => {
      if (hasOnlyAddressing) return false
      return prescriptions && prescriptions.length > 0
    })
  }, [allAnswers, depistagesNames, profile])

  const isEmptyPrescription = useMemo(() => {
    return (
      Object.values(prescriptionRecap).every((value) => !value) ||
      depistagesNames.length === 0
    )
  }, [prescriptionRecap, depistagesNames.length])

  const showInfoBox = Object.values(prescriptionRecap).some((value) => !value)

  const handleClick = useCallback(() => {
    const prescribedDepistages = (
      Object.keys(prescriptionRecap) as DepistageName[]
    ).filter((depistageName) => prescriptionRecap[depistageName])

    logger.info("Prescribed depistages", { prescribedDepistages })

    setPrescribedDepistages(prescribedDepistages)
    onSubmit()
  }, [prescriptionRecap, onSubmit])

  useEffect(() => {
    setHeader({ pageName: "Récapitulatif de vos prescriptions", progress: 80 })
    setTitle({
      text: "Récapitulatif de vos prescriptions",
      highlight: "vos prescriptions",
    })
    setShowBackButton(true)
    setCta({
      onClick: handleClick,
      text: "Finaliser et payer",
      isDisabled: isEmptyPrescription,
    })
  }, [isEmptyPrescription])

  useEffect(() => {
    // Scroll smoothly to the top of the page
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    })
  }, [])

  if (isEmptyPrescription) {
    return <EmptyPrescriptionRecap onRestart={onRestart} />
  }

  return (
    <Container maxW="container.xl" maxWidth="600px" mx="auto">
      <VStack gap={4} alignItems="flex-start" pt={4} pb={8}>
        {(
          Object.keys(prescriptionRecap) as (keyof typeof prescriptionRecap)[]
        ).map((depistageName) => (
          <PrescriptionRecapItem
            key={depistageName}
            despistageName={depistageName}
            isPrescribed={!!prescriptionRecap[depistageName]}
          />
        ))}
        {showInfoBox && (
          <>
            <VSpacer size={10} />
            <InfoBox
              isCollapsible={true}
              title="Explications"
              message="D'après vos réponses, certains dépistages ne sont finalement par recommandés pour vous à ce jour. Vous pouvez refaire une demande dans le futur."
            />
          </>
        )}
        <Text fontSize="sm" pl={2}>
          Nous avons besoin de vos coordonnées pour l&apos;ordonnance. En créant
          un compte sur l&apos;écran suivant, vous pourrez accéder à votre
          espace sécurisé et accéder à tous vos documents et échanges avec
          l&apos;équipe médicale
        </Text>
      </VStack>
    </Container>
  )
}

const EmptyPrescriptionRecap = ({ onRestart }: { onRestart: () => void }) => {
  const { setHeader, setTitle, setCta } = useQuestionnaireLayoutStore()

  useEffect(() => {
    setHeader({ pageName: "Récapitulatif de vos ordonnances", progress: 100 })
    setTitle("Pas de dépistage recommandé")
    setCta({
      onClick: onRestart,
      text: "Refaire le questionnaire",
    })
  }, [setHeader, setTitle, setCta, onRestart])

  return (
    <Container maxW="container.xl" py={8}>
      <VStack spacing={10} align="stretch">
        <Image
          src="/img/monster.webp"
          alt="Mascotte"
          maxW={200}
          alignSelf="center"
        />
        <Text fontWeight={600} textAlign="center">
          D&apos;après vos réponses au questionnaire, vous n&apos;êtes
          actuellement concerné par aucun des dépistages proposés par Bleu, ou
          vous n&apos;avez pas souhaité les réaliser pour l&apos;instant.
        </Text>
      </VStack>
    </Container>
  )
}
