import type { ComponentWithAs, IconProps } from "@chakra-ui/react"
import { VerticalName } from "@bleu/shared/verticals"
import { IconVerticalesCardio } from "@bleu/front/assets/icons/IconVerticalesCardio"
import { IconVerticalesGeneral } from "@bleu/front/assets/icons/IconVerticalesGeneral"
import { IconVerticalesOnco } from "@bleu/front/assets/icons/IconVerticalesOnco"
import { IconVerticalesSTI } from "@bleu/front/assets/icons/IconVerticalesSTI"
import { IconVerticalesSubstites } from "@bleu/front/assets/icons/IconVerticalesSubstitutes"
import { colors } from "@bleu/front/assets/theme/colors"

export interface VerticalTheme {
  icon: ComponentWithAs<"svg">
  color: IconProps["color"]
  verticalButtonGradient?: string
}
export const verticalsTheme: Record<VerticalName, VerticalTheme> = {
  [VerticalName.CARDIO]: {
    icon: IconVerticalesCardio,
    color: colors.pink[400],
    verticalButtonGradient:
      "radial-gradient(50% 50% at 50% 50%, #FEA2DB 0%, #F4E6FA 100%), #F4E6FA;",
  },
  [VerticalName.CANCER]: {
    icon: IconVerticalesOnco,
    color: colors.indigo[400],
    verticalButtonGradient:
      "radial-gradient(50% 50% at 50% 50%, #B5A8FF 0%, #EAE7FF 100%), #EAE7FF",
  },
  [VerticalName.STI]: {
    icon: IconVerticalesSTI,
    color: colors.yellow[400],
    verticalButtonGradient:
      "radial-gradient(50% 50% at 50% 50%, #F8E5A1 0%, #FDF8E6 100%), #FDF8E6",
  },
  [VerticalName.SUBSTITUTES]: {
    icon: IconVerticalesSubstites,
    color: colors.green[400],
    verticalButtonGradient:
      "radial-gradient(50% 50% at 50% 50%, #B6E2B2 0%, #E6F5E5 100%), #E6F5E5",
  },
  [VerticalName.GENERAL]: {
    icon: IconVerticalesGeneral,
    color: colors.blue[400],
    verticalButtonGradient:
      "radial-gradient(50% 50% at 50% 50%, rgba(0, 141, 239, 0.40) 0%, rgba(0, 141, 239, 0.10) 100%), #F9FCFF",
  },
}
