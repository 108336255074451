import { Center } from "@chakra-ui/react"
import { Outlet, useLocation } from "react-router-dom"
import { useClerk } from "@clerk/clerk-react"
import { useEffect } from "react"
import { useAmplitudeTrack } from "../../utils/tracking"

export const AuthLayout = () => {
  const { track } = useAmplitudeTrack()
  const location = useLocation()
  const clerk = useClerk()

  useEffect(() => {
    // Track AUTH_START when the auth page is loaded
    track("AUTH_START", {
      path: location.pathname,
      isSignIn: location.pathname.startsWith("/sign-in"),
    })

    // Listen for auth state changes
    const unsubscribe = clerk.addListener(({ user }) => {
      if (user) {
        track("AUTH_SUCCESS", {
          path: location.pathname,
          isSignIn: location.pathname.startsWith("/sign-in"),
        })
      }
    })

    return () => {
      unsubscribe()
    }
  }, [track, location.pathname, clerk])

  return (
    <Center
      w="100vw"
      h="100vh"
      bgRepeat="no-repeat"
      bgImage="/img/dots-background-2.webp"
      bgSize="cover"
    >
      <Outlet />
    </Center>
  )
}
