import { Text, Button, VStack } from "@chakra-ui/react"
import { IconLock, IconLockOpen } from "@tabler/icons-react"
import { useState } from "react"
import type { Conversation } from "@bleu/types/prisma-client"
import { User } from "@bleu/types/user"
import { ConversationChat } from "@bleu/front/components/ConversationChat"
import { PlaceholderChatInterface } from "@bleu/front/components/PlaceholderChat"
import { ConversationProvider } from "@bleu/front/components/seald/ConversationProvider"
import { SealdProvider } from "@bleu/front/components/seald/SealdProvider"
import { useCreateConversationMutation } from "@bleu/front/queries/seald"

type Props = {
  medicalRecordId: string
  patient: User
  conversation: Conversation | null
}

export const DoctorConversation = ({
  medicalRecordId,
  patient,
  conversation,
}: Props) => {
  const { mutateAsync: createConversation, isPending: isCreatingConversation } =
    useCreateConversationMutation(medicalRecordId, "conversation", patient)
  const [isHover, setIsHover] = useState(false)

  const handleMouseEnter = () => {
    setIsHover(true)
  }
  const handleMouseLeave = () => {
    setIsHover(false)
  }

  if (!conversation) {
    return (
      <PlaceholderChatInterface>
        <SealdProvider>
          <VStack gap={4} justifyContent="center" h="100%">
            <Text width="50%" textAlign="center" fontWeight="bold">
              Vous n&apos;avez pas encore de discussion en cours avec ce patient
              au sujet de ce dossier
            </Text>
            <Button
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              leftIcon={isHover ? <IconLockOpen /> : <IconLock />}
              onClick={() => createConversation()}
              isLoading={isCreatingConversation}
            >
              Démarrer une discussion chiffrée
            </Button>
          </VStack>
        </SealdProvider>
      </PlaceholderChatInterface>
    )
  }

  return (
    <ConversationProvider conversationId={conversation.id}>
      {({ conversation, sealdSession }) => (
        <ConversationChat
          conversation={conversation}
          sealdSession={sealdSession}
        />
      )}
    </ConversationProvider>
  )
}
