import { VStack, Heading } from "@chakra-ui/react"
import { IconCircleCheck } from "@tabler/icons-react"
import { useNavigate } from "react-router-dom"
import { colors } from "@bleu/front/assets/theme/colors"
import { VSpacer } from "@bleu/front/components/layout/Spacer"
import { AccountLayout } from "@bleu/front/pages/account/AccountLayout"

export const Success = () => {
  const navigate = useNavigate()
  return (
    <>
      <VStack align="center">
        <VSpacer size={10} />
        <IconCircleCheck size={70} color={colors.green[400]} />
        <VSpacer size={2} />
        <Heading size="3xl" textAlign="center">
          Votre compte a bien été mis à jour
        </Heading>
      </VStack>
      <AccountLayout.CTA onClick={() => navigate("/account/preferences")}>
        Retour
      </AccountLayout.CTA>
    </>
  )
}
