import {
  Box,
  Heading,
  HStack,
  Input,
  InputGroup,
  InputRightElement,
  Stack,
  Tab,
  TabList,
  Tabs,
  Text,
} from "@chakra-ui/react"
import { IconSearch } from "@tabler/icons-react"
import _ from "lodash"
import { useState } from "react"
import { DoctorResponses } from "@bleu/types/endpoints/doctor"
import { Pagination } from "@bleu/front/components/Pagination"
import { MedicalRecordsTable } from "@bleu/front/components/doctor/MedicalRecordsTable"
import { QueryWrapper } from "@bleu/front/components/layout/QueryWrapper"
import { useListMedicalRecordsQuery } from "@bleu/front/queries/medicalRecords"

type Props = {
  statusCounts?: DoctorResponses.Stats["countByStatus"]
}

export const MedicalRecordsList = ({ statusCounts }: Props) => {
  const [page, setPage] = useState(1)
  const [searchQuery, setSearchQuery] = useState("")
  const [statusTabIndex, setStatusTabIndex] = useState(0)

  const medicalRecordsQuery = useListMedicalRecordsQuery(
    page,
    searchQuery,
    statusCounts?.[statusTabIndex]?.key,
  )

  return (
    <Stack gap={8}>
      <HStack justify="space-between" ml={208}>
        <Heading>Mes patients</Heading>
        <InputGroup w={300}>
          <Input
            onChange={_.debounce((e) => setSearchQuery(e.target.value), 300)}
            placeholder="Rechercher un patient"
          />
          <InputRightElement color="navy.300">
            <IconSearch size={20} />
          </InputRightElement>
        </InputGroup>
      </HStack>
      <HStack gap={7} w="100%" h="100%" align="flex-start" pb={10}>
        <Tabs
          w={180}
          size="sm"
          orientation="vertical"
          index={statusTabIndex}
          onChange={setStatusTabIndex}
        >
          <TabList>
            {statusCounts?.map((statusInfo, index) => (
              <Tab key={statusInfo.key}>
                <HStack w={160} justify="space-between">
                  <Text>{statusInfo.label}</Text>
                  <Box
                    fontWeight="bold"
                    borderRadius={16}
                    color={index === statusTabIndex ? "blue.400" : "navy.300"}
                    bgColor="white"
                    px={2}
                    py={1}
                  >
                    {statusInfo.value}
                  </Box>
                </HStack>
              </Tab>
            ))}
          </TabList>
        </Tabs>
        <Stack gap={6} w="100%">
          <QueryWrapper query={medicalRecordsQuery}>
            {({ data }) => (
              <Stack>
                <MedicalRecordsTable medicalRecords={data.data} />
                <Pagination
                  page={data.page}
                  totalPages={data.totalPages}
                  onChangePage={setPage}
                />
              </Stack>
            )}
          </QueryWrapper>
        </Stack>
      </HStack>
    </Stack>
  )
}
