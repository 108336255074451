import { Flex, type FlexProps } from "@chakra-ui/react"
import { SelectBox } from "@bleu/front/components/forms/SelectBox"
import type {
  QuestionProps,
  SelectOption,
} from "@bleu/front/components/forms/types"
import { QuestionWrapper } from "./QuestionWrapper"

export type SingleSelectOption = SelectOption<string | boolean>

export interface SingleSelectQuestionProps
  extends QuestionProps<string | boolean> {
  options: SingleSelectOption[]
}

export function SingleSelectQuestion({
  title,
  subtitle,
  options,
  value,
  onChange,
}: SingleSelectQuestionProps) {
  const propsFor2Items: FlexProps = {
    justifyContent: "center",
    alignItems: "stretch",
    flexDirection: "row",
  }
  const propsForMoreThan2Items: FlexProps = { flexDirection: "column" }

  return (
    <QuestionWrapper title={title ?? ""} subtitle={subtitle ?? ""}>
      <Flex
        gap={5}
        alignItems="center"
        width="100%"
        {...(options.length === 2 ? propsFor2Items : propsForMoreThan2Items)}
      >
        {options.map((option) => (
          <SelectBox
            variant={value === option.value ? "select" : "default"}
            key={option.label}
            onClick={() => onChange(option.value)}
          >
            {option.label}
          </SelectBox>
        ))}
      </Flex>
    </QuestionWrapper>
  )
}
