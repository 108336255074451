import { Stack, Tab, TabList, TabPanels, Tabs } from "@chakra-ui/react"
import { useCallback, useMemo } from "react"
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom"
import { RedDot } from "@bleu/front/components/RedDot"
import { usePatientMedicalRecordsQuery } from "@bleu/front/queries/patients"

const PATIENT_RECORD_PAGES = [
  {
    tabName: "Suivi du dossier",
    href: "home",
  },
  {
    tabName: "QCM patient",
    href: "answers",
  },
  {
    tabName: "Notes",
    href: "notes",
  },
  {
    tabName: "Documents",
    href: "documents",
  },
  {
    tabName: "Messagerie",
    href: "conversation",
  },
] as const

export const PatientMedicalRecordLayout = () => {
  const location = useLocation()
  const navigate = useNavigate()

  const currentTabIndex = useMemo(() => {
    const tabIndex = PATIENT_RECORD_PAGES.findIndex(({ href }) =>
      location.pathname.includes(href),
    )
    return tabIndex >= 0 ? tabIndex : 0
  }, [location.pathname])

  const handleTabChange = useCallback(
    (tabIndex: number) => {
      const tab = PATIENT_RECORD_PAGES[tabIndex]
      if (tab) {
        navigate(tab.href)
      }
    },
    [navigate],
  )

  const { patientId, recordId } = useParams()
  const { data: medicalRecord } = usePatientMedicalRecordsQuery(
    patientId as string,
    recordId as string,
  )

  return (
    <Stack gap={12} w="100%" h="100%" mt={-2} px={2}>
      <Tabs size="sm" onChange={handleTabChange} defaultIndex={currentTabIndex}>
        <TabList>
          {PATIENT_RECORD_PAGES.map(({ tabName, href }) => (
            <Tab key={tabName} position="relative">
              {tabName}
              {href === "conversation" && medicalRecord?.hasNewMessage && (
                <RedDot />
              )}
            </Tab>
          ))}
        </TabList>
        <TabPanels py={4}>
          <Outlet />
        </TabPanels>
      </Tabs>
    </Stack>
  )
}
