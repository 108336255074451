import { HStack } from "@chakra-ui/react"
import { Link } from "react-router-dom"
import { UserMenu } from "@bleu/front/components/auth/UserMenu"
import { Logo } from "@bleu/front/components/layout/Logo"
import { useFunnelStore } from "@bleu/front/lib/stores"

export const Header = () => {
  const { reset: resetFunnelStore } = useFunnelStore()

  const handleLogoClick = () => {
    resetFunnelStore()
  }

  return (
    <HStack
      w="100%"
      h={["80px", "88px"]}
      px={5}
      py={4}
      marginBottom={5}
      justify="space-between"
    >
      <Link to="/" onClick={handleLogoClick}>
        <Logo />
      </Link>
      <UserMenu />
    </HStack>
  )
}
