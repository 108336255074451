import { useMutation, useQuery } from "@tanstack/react-query"
import { AccountRequests, type AccountResponses } from "@bleu/types/endpoints"
import { apiClient } from "@bleu/front/lib/apiClient"

export const useAccountPageQuery = () =>
  useQuery({
    // TODO: really a bad idea to store medical records under a different key!!! Let's fix it when we have time
    queryKey: ["account"],
    queryFn: async () => {
      const result =
        await apiClient.get<AccountResponses.AccountPageData>("/account")
      return result.data
    },
  })

export const useInvoicesPanelQuery = () =>
  useQuery({
    queryKey: ["invoices"],
    queryFn: async () => {
      const result =
        await apiClient.get<AccountResponses.InvoicesPanelData>(
          "/account/invoices",
        )
      return result.data
    },
  })

export const useConfirmPasswordMutation = () =>
  useMutation({
    mutationKey: ["confirmPassword"],
    mutationFn: async (password: string) => {
      const result = await apiClient.post<
        AccountRequests.ConfirmPassword,
        { data: AccountResponses.ConfirmPassword } // TODO isn't there a helper for that ?
      >("/account/confirm-password", {
        password,
      })

      return result.data.success
    },
  })

export const useUpdateEmailAddressMutation = () => {
  return useMutation({
    mutationFn: async (emailAddress: string) => {
      const result = await apiClient.post<
        AccountRequests.UpdateEmailAddress,
        { data: AccountResponses.UpdateEmailAddress }
      >("/account/email", {
        emailAddress,
      })

      return result.data.success
    },
  })
}

export const useUpdatePhoneNumberMutation = () => {
  return useMutation({
    mutationFn: async (phoneNumber: string) => {
      const result = await apiClient.post<
        AccountRequests.UpdatePhoneNumber,
        { data: AccountResponses.UpdatePhoneNumber }
      >("/account/phoneNumber", {
        phoneNumber,
      })

      return result.data.success
    },
  })
}

export const useUpdatePasswordMutation = () => {
  return useMutation({
    mutationFn: async (password: string) => {
      const result = await apiClient.post<
        AccountRequests.UpdatePassword,
        { data: AccountResponses.UpdatePassword }
      >("/account/password", {
        password,
      })

      return result.data.success
    },
  })
}
