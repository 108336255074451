import { Button, VStack } from "@chakra-ui/react"
import { IconLockOpen } from "@tabler/icons-react"
import { SealdSignInComponentProps } from "@bleu/front/components/seald/SealdProvider"

export const BasicSealdSignInComponent = ({
  onSignIn,
  isSigningIn,
}: SealdSignInComponentProps) => (
  <VStack justifyContent="space-around" h="100%">
    <Button
      isLoading={isSigningIn}
      onClick={onSignIn}
      leftIcon={<IconLockOpen />}
    >
      Déverrouiller le contenu
    </Button>
  </VStack>
)
