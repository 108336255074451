import {
  Box,
  Container,
  Heading,
  Text,
  VStack,
  SimpleGrid,
  Icon,
} from "@chakra-ui/react"
import IconHeartPicto from "../../assets/icons/IconHeartPicto"
import IconMedicalCirclePicto from "../../assets/icons/IconMedicalCirclePicto"
import IconSendPicto from "../../assets/icons/IconSendPicto"
import { useAmplitudeTrack } from "@bleu/front/utils/tracking"
import { useEffect } from "react"

interface ProcessStep {
  icon: React.ElementType
  title: string
  description: string
  subDescription?: string
}

const processSteps: ProcessStep[] = [
  {
    icon: IconHeartPicto,
    title: "1/ Je réponds aux questions et j'obtiens mon ordonnance",
    description: "Temps estimé: 2 à 3 minutes",
  },
  {
    icon: IconMedicalCirclePicto,
    title: "2/ Je réalise mes examens",
    description: "En laboratoire de biologie médicale",
  },
  {
    icon: IconSendPicto,
    title: "3/ Suivi Médical",
    description:
      "J'envoie mes résultats à Bleu. Traitement, prise en charge : notre équipe médicale vous accompagne.",
  },
]

export function Start() {
  const { track } = useAmplitudeTrack()

  useEffect(() => {
    track("CONSENT_START", {})
  }, [track])

  return (
    <Box as="main" className="flex-grow">
      <Container maxW="container.xl" py={8}>
        <VStack spacing={6} align="stretch">
          <Heading as="h1" size="xl" mb={2}>
            Le parcours{" "}
            <Text as="span" color="blue.500">
              Bleu
            </Text>{" "}
            en 3 étapes pour votre dépistage
          </Heading>
          <Heading as="h2" size="md" mb={6}>
            Un accompagnement de bout en bout, sans attente ni déplacement pour
            avoir un rendez-vous chez le médecin
          </Heading>
          <SimpleGrid columns={{ base: 1, md: 3 }} spacing={6} mb={12}>
            {processSteps.map((step, index) => (
              <Box
                key={index}
                bg="white"
                borderRadius="lg"
                boxShadow="none"
                border="none"
                p={6}
                textAlign="center"
              >
                <VStack spacing={4}>
                  <Icon as={step.icon} boxSize={12} color="blue.500" />
                  <Heading as="h3" size="md">
                    {step.title}
                  </Heading>
                  <Text color="gray.600">{step.description}</Text>
                  {step.subDescription && (
                    <Text color="gray.500">{step.subDescription}</Text>
                  )}
                </VStack>
              </Box>
            ))}
          </SimpleGrid>
        </VStack>
      </Container>
    </Box>
  )
}
