import { createContext, useContext } from "react"
import { Doctor } from "@bleu/types/prisma-client"

type DoctorContextType = {
  doctorInfo: Doctor
}

export const DoctorContext = createContext<DoctorContextType>({
  // @ts-expect-error Creating this context to have an absolute to a defined user object.
  // Context shall not be used if user doesn't exist
  doctorInfo: undefined,
})

export const useDoctorInfo = () => {
  const context = useContext(DoctorContext)
  if (!context?.doctorInfo) {
    throw new Error(
      "useDoctorInfo must be used under DoctorGuard-wrapped routes",
    )
  }
  return context.doctorInfo
}
