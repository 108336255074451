import {
  Button,
  HStack,
  Radio,
  RadioGroup,
  Stack,
  Textarea,
} from "@chakra-ui/react"
import { EncryptionSession } from "@seald-io/sdk/lib/main"
import { useState } from "react"
import { MedicalRecordRequests } from "@bleu/types/endpoints/index"
import { useAnalyseLabResultMutation } from "@bleu/front/queries/medicalRecords"

type Props = {
  patientId: string
  medicalRecordId: string
  labResultId: string
  conversationId: string
  sealdSession: EncryptionSession
}

export const LabResultsAnalyzerForm = ({
  patientId,
  medicalRecordId,
  labResultId,
  conversationId,
  sealdSession,
}: Props) => {
  const [labResultStatus, setLabResultStatus] =
    useState<MedicalRecordRequests.LabResultAnalysis["status"]>()
  const [labResultAnalysis, setLabResultAnalysis] = useState<string>()

  const { mutateAsync: sendAnalysis, isPending } = useAnalyseLabResultMutation(
    patientId,
    medicalRecordId,
    labResultId,
    conversationId,
    sealdSession,
  )

  return (
    <Stack gap={5}>
      <RadioGroup
        value={labResultStatus}
        onChange={(status: MedicalRecordRequests.LabResultAnalysis["status"]) =>
          setLabResultStatus(status)
        }
      >
        <HStack>
          <Radio
            border="2px solid"
            borderColor="green.600"
            colorScheme="green"
            value="FAVORABLE"
          >
            Favorable
          </Radio>
          <Radio
            border="2px solid"
            borderColor="red.600"
            colorScheme="red"
            value="UNFAVORABLE"
          >
            Défavorable
          </Radio>
        </HStack>
      </RadioGroup>

      <Textarea
        h={300}
        placeholder={"Commentez l'examen..."}
        value={labResultAnalysis}
        onChange={(e) => setLabResultAnalysis(e.target.value)}
      />

      <HStack justify="flex-end">
        <Button
          type="submit"
          isLoading={isPending}
          isDisabled={!labResultStatus || !labResultAnalysis}
          onClick={() =>
            sendAnalysis({
              status: labResultStatus!,
              analysis: labResultAnalysis!,
            })
          }
        >
          Valider
        </Button>
      </HStack>
    </Stack>
  )
}
