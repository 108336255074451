import { User as ClerkUser } from "@clerk/express"

import { User as PrismaUser } from "@bleu/types/prisma-client"
import { User, UserRole } from "@bleu/types/user"

export const checkUserAuthorization = (
  user: User,
  role: UserRole,
  params: Record<string, unknown>,
) => {
  switch (role) {
    case UserRole.ADMIN:
      return user.role === UserRole.ADMIN
    case UserRole.DOCTOR:
      return user.role === UserRole.DOCTOR || user.role === UserRole.ADMIN
    case UserRole.USER:
      if (user.role === UserRole.USER && params.userId)
        return user.id === params.userId
      return true
  }
}

export const buildUserObject = (
  clerkUser: ClerkUser,
  prismaUser: PrismaUser,
): User => {
  const emailResource =
    clerkUser.emailAddresses.find(
      (e) => e.id === clerkUser.primaryEmailAddressId,
    ) ?? clerkUser.emailAddresses[0] // Because for an unknown reason, sometimes primaryEmailAddressId is null
  const phoneNumber =
    clerkUser.phoneNumbers.find(
      (p) => p.id === clerkUser.primaryPhoneNumberId,
    ) ?? clerkUser.phoneNumbers[0] // Because for an unknown reason, sometimes primaryPhoneNumberId is null

  return {
    id: prismaUser.id,
    clerkId: clerkUser.id,
    // Clerk's configuration forces the user to define their first and last name, so we can safely constraint the return type
    emailAddress: emailResource!.emailAddress,
    firstName: clerkUser.firstName!, // TODO: we might need to popup a modal to ask for firstName, if there's a case it's null (although shouldn't be)
    lastName: clerkUser.lastName!,
    fullName: `${clerkUser.firstName} ${clerkUser.lastName}`,
    role: clerkUser.publicMetadata.role ?? UserRole.USER,
    avatarUrl: clerkUser.imageUrl ?? undefined,
    sealdId: prismaUser.sealdId ?? undefined,
    sealdTwoManRuleKey: prismaUser.sealdTwoManRuleKey,
    sealdDatabaseKey: prismaUser.sealdTwoManRuleKey,
    phoneNumber: phoneNumber.phoneNumber,
  }
}
