import {
  Text,
  Button,
  Input,
  VStack,
  Heading,
  FormControl,
  useToast,
  FormErrorMessage,
} from "@chakra-ui/react"
import { useForm, zodResolver } from "@mantine/form"
import { useState, type KeyboardEventHandler, useContext } from "react"
import { useNavigate } from "react-router-dom"
import { z } from "zod"
import { zPhone } from "@bleu/shared/forms/validation"
import { UserContext } from "@bleu/front/components/auth/UserContext"
import { VSpacer } from "@bleu/front/components/layout/Spacer"
import { ConfirmPassword } from "@bleu/front/pages/account/ConfirmPassword"
import { useUpdatePhoneNumberMutation } from "@bleu/front/queries/account"

interface UpdatePhoneNumberFormProps {
  onNext: () => void
}
const UpdatePhoneNumberForm = ({ onNext }: UpdatePhoneNumberFormProps) => {
  const { user, updateUser } = useContext(UserContext)
  const [error, setError] = useState(false)

  const { mutateAsync: updatePhoneNumber, isPending } =
    useUpdatePhoneNumberMutation()

  const toast = useToast()
  const form = useForm({
    initialValues: {
      phoneNumber: "",
    },
    validate: zodResolver(z.object({ phoneNumber: zPhone })),
  })

  const handleNext = async () => {
    setError(false)

    const phoneNumber = form.validate()
    if (phoneNumber.hasErrors) {
      setError(true)
      return
    }

    const success = await updatePhoneNumber(form.values.phoneNumber)

    if (success) {
      updateUser({ ...user, phoneNumber: form.values.phoneNumber })
      onNext()
    } else {
      toast({
        title: "Erreur lors de la mise à jour du numéro de téléphone",
        status: "error",
      })
    }
  }

  const handleKeyDown: KeyboardEventHandler = (e) => {
    if (e.key === "Enter" && !isPending) {
      handleNext()
    }
  }

  const isDisabled = !form.isValid()

  return (
    <>
      <VStack p={0} alignItems="stretch" gap={0}>
        <Heading size="2xl">Nouveau numéro de téléphone</Heading>
        <VSpacer size={2} />
        <Text>Veuillez renseigner votre nouveau numéro de téléphone</Text>
        <VSpacer size={6} />
        <FormControl isInvalid={error}>
          <Input
            type="tel"
            errorBorderColor="red.500"
            placeholder="Nouveau numéro de téléphone"
            onKeyDown={handleKeyDown}
            {...form.getInputProps("phoneNumber")}
          />
          <FormErrorMessage>
            Ce numéro de téléphone n&apos;est pas valide
          </FormErrorMessage>
        </FormControl>
      </VStack>
      <Button
        position="absolute"
        bottom={10}
        w="100%"
        onClick={handleNext}
        isDisabled={isDisabled}
        isLoading={isPending}
      >
        Continuer
      </Button>
    </>
  )
}

export const UpdatePhoneNumber = () => {
  const [isPasswordConfirmed, setIsPasswordConfirmed] = useState(false)
  const navigate = useNavigate()

  const onNext = () => {
    if (!isPasswordConfirmed) {
      setIsPasswordConfirmed(true)
      return
    }
    navigate("../success")
  }

  return !isPasswordConfirmed ? (
    <ConfirmPassword onConfirm={onNext} />
  ) : (
    <UpdatePhoneNumberForm onNext={onNext} />
  )
}
