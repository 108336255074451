import { useParams } from "react-router-dom"
import { LabResultsAnalyzer } from "@bleu/front/components/doctor/LabResultsAnalyzer"
import { QueryWrapper } from "@bleu/front/components/layout/QueryWrapper"
import { NotFoundPage } from "@bleu/front/pages/NotFoundPage"
import { usePatientMedicalRecordsQuery } from "@bleu/front/queries/patients"

export const LabResultsAnalysisPage = () => {
  const { patientId, recordId, documentId } = useParams()
  const medicalRecordQuery = usePatientMedicalRecordsQuery(
    patientId as string,
    recordId as string,
  )

  return (
    <QueryWrapper query={medicalRecordQuery}>
      {({ data }) => {
        const labResults = data.documents.find((doc) => doc.id === documentId)
        if (labResults) {
          return (
            <LabResultsAnalyzer medicalRecord={data} labResult={labResults} />
          )
        }
        // TODO: can probably do better than this
        return <NotFoundPage />
      }}
    </QueryWrapper>
  )
}
