import {
  Text,
  Button,
  Input,
  VStack,
  Heading,
  FormControl,
  useToast,
  FormErrorMessage,
  Stack,
} from "@chakra-ui/react"
import { useForm, zodResolver } from "@mantine/form"
import { IconLockOpen } from "@tabler/icons-react"
import { useState, type KeyboardEventHandler, useContext } from "react"
import { useNavigate } from "react-router-dom"
import { z } from "zod"
import { SealdResponses } from "@bleu/types/endpoints/seald"
import { UserContext, useUser } from "@bleu/front/components/auth/UserContext"
import { VSpacer } from "@bleu/front/components/layout/Spacer"
import { SealdChallengeForm } from "@bleu/front/components/seald/SealdChallengeForm"
import {
  SealdProvider,
  SealdSignInComponentProps,
} from "@bleu/front/components/seald/SealdProvider"
import { useUpdateEmailAddressMutation } from "@bleu/front/queries/account"
import { useSealdSignInMutation } from "@bleu/front/queries/seald"

interface UpdateEmailAddressFormProps {
  onNext: (emailAddress: string) => void
}
const UpdateEmailAddressForm = ({ onNext }: UpdateEmailAddressFormProps) => {
  const [error, setError] = useState(false)

  const { mutateAsync: updateEmailAddress, isPending } =
    useUpdateEmailAddressMutation()

  const toast = useToast()
  const form = useForm({
    initialValues: {
      email: "",
    },
    validate: zodResolver(z.object({ email: z.string().email() })),
  })

  const handleNext = async () => {
    setError(false)

    const emailAddress = form.validate()
    if (emailAddress.hasErrors) {
      setError(true)
      return
    }

    const success = await updateEmailAddress(form.values.email)

    if (success) {
      onNext(form.values.email)
    } else {
      toast({
        title: "Erreur lors de la mise à jour de l'adresse e-mail",
        status: "error",
      })
    }
  }

  const handleKeyDown: KeyboardEventHandler = (e) => {
    if (e.key === "Enter" && !isPending) {
      handleNext()
    }
  }

  const isDisabled = !form.isValid()

  return (
    <>
      <VStack p={0} alignItems="stretch" gap={0}>
        <Heading size="2xl">Nouvelle adresse e-mail</Heading>
        <VSpacer size={2} />
        <Text>Veuillez renseigner votre nouvelle adresse e-mail</Text>
        <VSpacer size={6} />
        <FormControl isInvalid={error}>
          <Input
            type="email"
            errorBorderColor="red.500"
            placeholder="Nouvelle adresse e-mail"
            onKeyDown={handleKeyDown}
            {...form.getInputProps("email")}
          />
          <FormErrorMessage>
            Cette adresse email n&apos;est pas valide
          </FormErrorMessage>
        </FormControl>
      </VStack>
      <Button
        position="absolute"
        bottom={10}
        w="100%"
        onClick={handleNext}
        isDisabled={isDisabled}
        isLoading={isPending}
      >
        Continuer
      </Button>
    </>
  )
}

const SealdSignInComponent = ({
  onSignIn,
  isSigningIn,
}: SealdSignInComponentProps) => (
  <VStack>
    <Stack
      gap={4}
      bg="white"
      p={6}
      mx={4}
      boxShadow="md"
      borderRadius={8}
      maxW={400}
    >
      <Heading>Vérifiez votre adresse email</Heading>
      <Text mb={8}>
        La vérification de votre adresse email actuelle est nécessaire pour sa
        mise à jour.
      </Text>
      <Button
        isLoading={isSigningIn}
        onClick={onSignIn}
        leftIcon={<IconLockOpen />}
      >
        Vérifier mon adresse email
      </Button>
    </Stack>
  </VStack>
)

export const UpdateEmailAddress = () => {
  const user = useUser()
  const navigate = useNavigate()

  const { updateUser } = useContext(UserContext)

  const [newEmail, setNewEmail] = useState<string>()
  const [tmrChallenge, setTmrChallenge] =
    useState<SealdResponses.TwoManRuleChallenge>()

  const { mutateAsync: getTMRChallenge } = useSealdSignInMutation(true)

  const handleEmailUpdated = async (updatedEmail: string) => {
    setNewEmail(updatedEmail)
    updateUser({ ...user, emailAddress: updatedEmail })

    const tmrChallenge = await getTMRChallenge(undefined)
    if (tmrChallenge.tmrChallenge.mustAuthenticate) {
      setTmrChallenge(tmrChallenge.tmrChallenge)
    }
  }

  const handleUpdateSealdEmail = async () => {
    navigate("../success")
  }

  return (
    <SealdProvider signInComponent={SealdSignInComponent}>
      {tmrChallenge ? (
        <SealdChallengeForm
          userId={user.id}
          email={newEmail!}
          sealdId={user.sealdId}
          twoManRuleChallenge={tmrChallenge}
          onChallengeSuccess={handleUpdateSealdEmail}
          showChatPlaceHolder={false}
        />
      ) : (
        <UpdateEmailAddressForm onNext={handleEmailUpdated} />
      )}
    </SealdProvider>
  )
}
