import {
  Button,
  Input,
  InputGroup,
  InputRightAddon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react"
import { useForm, zodResolver } from "@mantine/form"
import { useMutation } from "@tanstack/react-query"
import {
  logPhoneCallSchema,
  PhoneCallsRequests,
} from "@bleu/types/endpoints/phoneCalls"
import { apiClient } from "@bleu/front/lib/apiClient"
import { queryClient } from "@bleu/front/lib/queryClient"

type Props = {
  medicalRecordId: string
  phoneCallId: string
  isOpen: boolean
  onClose: () => void
}

export const PhoneCallLogModal = ({
  medicalRecordId,
  phoneCallId,
  isOpen,
  onClose,
}: Props) => {
  const form = useForm<PhoneCallsRequests.LogPhoneCall>({
    validate: zodResolver(logPhoneCallSchema),
  })

  const { mutateAsync: logPhoneCall, isPending } = useMutation({
    mutationFn: async (data: PhoneCallsRequests.LogPhoneCall) => {
      await apiClient.put(`/phone-calls/${phoneCallId}`, data)
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ["medical-records", medicalRecordId],
      })
      onClose()
    },
  })

  return (
    <Modal size="xs" isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <form onSubmit={form.onSubmit((values) => logPhoneCall(values))}>
        <ModalContent>
          <ModalHeader>Tracer l&apos;appel téléphonique</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <InputGroup>
              <Input {...form.getInputProps("durationInMinutes")} />
              <InputRightAddon>minutes</InputRightAddon>
            </InputGroup>
          </ModalBody>
          <ModalFooter>
            <Button
              type="submit"
              isLoading={isPending}
              isDisabled={!form.isValid()}
            >
              Enregistrer
            </Button>
          </ModalFooter>
        </ModalContent>
      </form>
    </Modal>
  )
}
