import { HStack } from "@chakra-ui/react"
import _ from "lodash"
import { Document } from "@bleu/types/prisma-client"
import { InfoBox } from "@bleu/front/components/InfoBox"
import { PatientDocument } from "@bleu/front/components/doctor/PatientDocument"

type Props = {
  documents: Document[]
}

export const PatientDocuments = ({ documents }: Props) => {
  if (!documents.length) {
    return (
      <InfoBox
        title="Encore aucun document pour ce patient"
        message={
          "Les ordonnances délivrées et résultats d'examen s'afficheront ici."
        }
      />
    )
  }

  return (
    <HStack wrap="wrap" align="flex-start" gap={5}>
      {_.sortBy(documents, (doc) => -new Date(doc.createdAt).getTime()).map(
        (doc) => (
          <PatientDocument key={doc.id} doc={doc} />
        ),
      )}
    </HStack>
  )
}
