import { Box, Container, HStack } from "@chakra-ui/react"
import type { PropsWithChildren } from "react"
import { Outlet } from "react-router-dom"
import { BackButton } from "@bleu/front/components/layout/BackButton"
import { Header } from "@bleu/front/components/layout/Header"
import { LayoutTitle } from "@bleu/front/components/layout/components/LayoutTitle"

const PatientHomeLayout = () => (
  <Box minH="100vh" maxH="minContent" position="relative">
    <Header />
    <Container maxW="container.xl" mb={10}>
      <Outlet />
    </Container>
  </Box>
)

interface PatientHomeLayoutHeaderProps extends PropsWithChildren {
  onBack: () => void
}

const PatientHomeLayoutHeader = ({
  onBack,
  children,
}: PatientHomeLayoutHeaderProps) => {
  return (
    <HStack alignItems="start" justifyContent="flex-start" gap={6}>
      <BackButton onClick={onBack} />
      {children}
    </HStack>
  )
}

PatientHomeLayout.Header = PatientHomeLayoutHeader
PatientHomeLayout.Title = LayoutTitle

export default PatientHomeLayout
