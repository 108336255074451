import { create } from "zustand"
import { devtools, persist } from "zustand/middleware"
import { ProfileData } from "@bleu/shared/questionnaires"

interface ProfileState {
  profile: ProfileData | null
  acceptsMarketing: boolean
  cgu: boolean
  setProfile: (profile: ProfileData) => void
  setAcceptsMarketing: (acceptsMarketing: boolean) => void
  setCgu: (cgu: boolean) => void
  clearProfile: () => void
}

export const useProfileStore = create<ProfileState>()(
  devtools(
    persist(
      (set) => ({
        profile: null,
        acceptsMarketing: false,
        cgu: false,
        setProfile: (profile) => set({ profile }),
        setAcceptsMarketing: (acceptsMarketing) => set({ acceptsMarketing }),
        setCgu: (cgu) => set({ cgu }),
        clearProfile: () =>
          set({ profile: null, acceptsMarketing: false, cgu: false }),
      }),
      {
        name: "profile-storage",
      },
    ),
  ),
)
