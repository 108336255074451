import { useParams } from "react-router-dom"
import { DoctorConversation } from "@bleu/front/components/doctor/DoctorConversation"
import { QueryWrapper } from "@bleu/front/components/layout/QueryWrapper"
import { usePatientMedicalRecordsQuery } from "@bleu/front/queries/patients"

export const MedicalRecordConversationPage = () => {
  const { patientId, recordId } = useParams()
  const medicalRecordQuery = usePatientMedicalRecordsQuery(
    patientId as string,
    recordId as string,
  )

  return (
    <QueryWrapper query={medicalRecordQuery}>
      {({ data }) => (
        <DoctorConversation
          medicalRecordId={data.id}
          patient={data.patient}
          conversation={data.conversation}
        />
      )}
    </QueryWrapper>
  )
}
