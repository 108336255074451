import { Text, VStack, Input, HStack, IconButton } from "@chakra-ui/react"
import { useUser as useClerkUser } from "@clerk/clerk-react"
import { IconPencil } from "@tabler/icons-react"
import { Link } from "react-router-dom"
import { useUser } from "@bleu/front/components/auth/UserContext"

export const PreferencesPanel = () => {
  const { emailAddress, phoneNumber } = useUser()
  const { user: clerkUser } = useClerkUser()

  const fields = [
    {
      label: "Adresse email",
      value: emailAddress,
      href: "email",
      type: "email",
    },
    {
      label: "Numéro de téléphone",
      value: phoneNumber,
      href: "phone",
      type: "tel",
    },
    {
      label: "Mot de passe",
      value: "**********",
      href: "password",
      type: "password",
      isDisabled: clerkUser?.verifiedExternalAccounts.length,
    },
  ]

  return (
    <VStack gap={4} alignItems="stretch">
      {fields.map(({ label, type, value, href, isDisabled }) => (
        <VStack key={label} align="stretch">
          <Text>{label}</Text>
          <HStack>
            <Input type={type} value={value} readOnly />
            {/* @ts-expect-error: to is set, at the same time as "as" so it's not missing... */}
            <IconButton
              {...(isDisabled ? { isDisabled } : { as: Link, to: href })}
              icon={<IconPencil />}
              aria-label={`Modifier ${label}`}
              variant="outline"
              borderRadius={8}
            />
          </HStack>
        </VStack>
      ))}
    </VStack>
  )
}
