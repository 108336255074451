import { Button, HStack, Skeleton, Stack } from "@chakra-ui/react"
import { IconUserPlus } from "@tabler/icons-react"
import { Link } from "react-router-dom"
import { UserRole } from "@bleu/types/user"
import { DOCTOR_STAT_BLOCKS } from "@bleu/shared/medicalRecords/doctorStats"
import { checkUserAuthorization } from "@bleu/shared/utils/auth"
import { useUser } from "@bleu/front/components/auth/UserContext"
import { MedicalRecordsList } from "@bleu/front/components/doctor/MedicalRecordsList"
import { StatBlock } from "@bleu/front/components/doctor/StatBlock"
import { Blue } from "@bleu/front/components/layout/Blue"
import { DoctorLayout } from "@bleu/front/components/layout/DoctorLayout"
import { useDoctorStatsQuery } from "@bleu/front/queries/doctor"

export const DoctorHomePage = () => {
  const user = useUser()

  const { data: stats, isLoading: isLoadingStats } = useDoctorStatsQuery()

  return (
    <Stack gap={6} w="100%" h="100%">
      <Stack gap={8} ml={208}>
        <HStack justify="space-between">
          <DoctorLayout.Title>
            Bonjour <Blue>{user.firstName}</Blue>,
          </DoctorLayout.Title>
          {checkUserAuthorization(user, UserRole.ADMIN, {}) && (
            <Button
              as={Link}
              to="/doctor/new-doctor"
              leftIcon={<IconUserPlus />}
              variant="outline"
              size="sm"
            >
              Inviter un nouveau médecin
            </Button>
          )}
        </HStack>
        <HStack gap={5} align="stretch">
          {isLoadingStats
            ? DOCTOR_STAT_BLOCKS.map((stat) => (
                <Skeleton key={stat.key} w={130} h={110} borderRadius={8} />
              ))
            : stats?.blocks.map((stat) => (
                <StatBlock
                  key={stat.key}
                  label={stat.label}
                  value={stat.value}
                />
              ))}
        </HStack>
      </Stack>
      <MedicalRecordsList statusCounts={stats?.countByStatus} />
    </Stack>
  )
}
