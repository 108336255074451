import {
  Icon,
  Text,
  Divider,
  HStack,
  Switch,
  VStack,
  Link,
  Box,
  Container,
} from "@chakra-ui/react"
import { useEffect, useMemo } from "react"
import { useNavigate } from "react-router-dom"
import { type DepistageName } from "@bleu/shared/depistages"
import { type ProfileData } from "@bleu/shared/questionnaires"
import { computeBodyMassIndexFromProfile } from "@bleu/shared/questionnaires/utils"
import { VerticalsLongNames } from "@bleu/shared/verticals"
import { verticalsTheme } from "@bleu/front/assets/theme/verticalsTheme"
import { InfoBox } from "@bleu/front/components/InfoBox"
import { Bold } from "@bleu/front/components/layout/Bold"
import { useFunnelStore } from "@bleu/front/lib/stores"
import { useQuestionnaireLayoutStore } from "@bleu/front/lib/stores/questionnaireLayoutStore"
import { Steps } from "./hooks"
import { useDepistages } from "./hooks/useDepistages"
import { useInitializeDepistages } from "./hooks/useInitializeDepistages"

interface Props {
  profile: ProfileData
}

export const DepistagesSelection = ({ profile }: Props) => {
  const { setHeader, setTitle, setCta, setShowBackButton } =
    useQuestionnaireLayoutStore()
  const { lastCompletedStep } = useFunnelStore()
  const navigate = useNavigate()
  const { prescribedDepistages, setPrescribedDepistages } = useFunnelStore()
  const { relevantDepistages } = useDepistages()
  const {
    excludedDepistages: initializedExcludedDepistages,
    setExcludedDepistages: initializedSetExcludedDepistages,
  } = useInitializeDepistages(profile)

  useEffect(() => {
    if (lastCompletedStep === Steps.depistagesSelection) {
      navigate("questionnaire?step=3")
    }
  }, [lastCompletedStep, navigate])

  useEffect(() => {
    // Scroll smoothly to the top of the page
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    })
  }, [])

  useEffect(() => {
    setHeader({ pageName: VerticalsLongNames.general, progress: 50 })
    setTitle({
      text: "Vos recommandations prioritaires",
      highlight: "prioritaires",
    })
    setShowBackButton(true)
  }, [setHeader, setTitle, setShowBackButton])

  const handleToggleDepistage = (depistage: DepistageName) => {
    if (initializedExcludedDepistages.includes(depistage)) {
      initializedSetExcludedDepistages(
        initializedExcludedDepistages.filter((d) => d !== depistage),
      )
      setPrescribedDepistages([...prescribedDepistages, depistage])
    } else {
      initializedSetExcludedDepistages([
        ...initializedExcludedDepistages,
        depistage,
      ])
      setPrescribedDepistages(
        prescribedDepistages.filter((d) => d !== depistage),
      )
    }
  }

  const isDisabled = prescribedDepistages.length === 0

  useEffect(() => {
    setCta({
      onClick: () => navigate("/questionnaire?step=3"),
      isDisabled,
      text: "Continuer",
    })
  }, [isDisabled, navigate, setCta])

  const depistagesWithExcludedStatus = useMemo(() => {
    return relevantDepistages.map((depistage) => ({
      ...depistage,
      isExcluded: initializedExcludedDepistages.includes(depistage.name),
    }))
  }, [relevantDepistages, initializedExcludedDepistages])

  return (
    <Container maxW="container.xl" maxWidth="600px" mx="auto">
      <VStack spacing={6} align="stretch" pt={4} pb={8}>
        <Text fontSize="sm">
          <Text>
            Les actions ci-dessous couvrent les{" "}
            <Bold>principaux facteurs de risque</Bold> vous concernant.
          </Text>
          <Text mt={4}>
            Elles sont conformes aux directives de la{" "}
            <Link
              href="https://www.has-sante.fr/"
              target="_blank"
              color="blue.500"
            >
              Haute Autorité de Santé
            </Link>{" "}
            et à{" "}
            <Link
              href="https://www.bleu.care/blog/methodologie-recommandations"
              target="_blank"
              color="blue.500"
            >
              notre méthodologie de recommandation
            </Link>
            .
          </Text>
        </Text>

        <HStack
          borderRadius="9px"
          bgColor="blue.200"
          height="98px"
          padding={4}
          justifyContent="space-between"
        >
          <VStack alignItems="flex-start" spacing={0}>
            <Text fontSize="2xl">Votre IMC</Text>
            <Text fontSize="xs" color="navy.300">
              *Indice de masse
              <br />
              corporelle
            </Text>
          </VStack>
          <Text fontSize="4xl" color="blue.400">
            {computeBodyMassIndexFromProfile(profile).toFixed(1)}
          </Text>
        </HStack>

        <Divider />

        <VStack spacing={4} align="stretch" divider={<Divider />}>
          {depistagesWithExcludedStatus.map((depistage) => {
            const {
              name,
              longName,
              subtitle,
              description,
              moreInfo,
              vertical,
              isExcluded,
            } = depistage
            return (
              <Box key={name} opacity={isExcluded ? 0.5 : 1}>
                <HStack justifyContent="space-between" alignItems="flex-start">
                  <HStack alignItems="flex-start" spacing={6} pr={8}>
                    <Icon
                      as={verticalsTheme[vertical].icon}
                      color={verticalsTheme[vertical].color}
                      boxSize={5}
                    />
                    <VStack spacing={0} align="start" mt={-1}>
                      <Text fontSize="lg" fontWeight={600}>
                        {longName}
                      </Text>
                      <Text color="navy.300" fontSize="sm">
                        {subtitle}
                      </Text>
                    </VStack>
                  </HStack>
                  <Switch
                    isChecked={!isExcluded}
                    onChange={() => handleToggleDepistage(name)}
                  />
                </HStack>
                <Text fontSize="sm" color="navy.400" mt={6} mb={4}>
                  {description}
                </Text>
                {moreInfo && (
                  <InfoBox
                    isCollapsible={true}
                    title="En savoir plus"
                    message={moreInfo}
                  />
                )}
              </Box>
            )
          })}
        </VStack>

        <Divider borderColor="gray.400" />

        <InfoBox
          title="Avertissement"
          message="Si vous avez des des antécédents personnels ou familiaux, des symptômes ou une pathologie connue en cours, d'autres dépistages pourraient aussi être pertinents mais relèvent d'une consultation médicale. Parlez-en à votre médecin."
          status="warning"
        />
      </VStack>
    </Container>
  )
}
