import { CalendlyButton } from "@bleu/front/components/CalendlyButton"
import { ConversationChat } from "@bleu/front/components/ConversationChat"
import { PlaceholderChatInterface } from "@bleu/front/components/PlaceholderChat"
import { RedDot } from "@bleu/front/components/RedDot"
import { Score2Card } from "@bleu/front/components/Score2Card"
import PatientHomeLayout from "@bleu/front/components/layout/PatientHomeLayout"
import { QueryWrapper } from "@bleu/front/components/layout/QueryWrapper"
import { VSpacer } from "@bleu/front/components/layout/Spacer"
import {
  Timeline,
  type TimelineItem,
} from "@bleu/front/components/patientSpace/Timeline"
import { ConversationProvider } from "@bleu/front/components/seald/ConversationProvider"
import { apiClient } from "@bleu/front/lib/apiClient"
import { DownloadDocumentButton } from "@bleu/front/pages/DownloadDocumentButton"
import { UploadDocumentButton } from "@bleu/front/pages/UploadDocumentButton"
import { useMedicalRecordPageDataQuery } from "@bleu/front/queries/medicalRecords"
import { logger } from "@bleu/front/utils/logger"
import {
  isMedicalRecordStatusBefore,
  type MedicalRecordStatus,
  MedicalRecordStatuses,
} from "@bleu/shared/medicalRecords/statuses"
import type { MedicalRecordResponses } from "@bleu/types/endpoints"
import {
  Box,
  Button,
  Divider,
  HStack,
  Stack,
  Text,
  VStack,
} from "@chakra-ui/react"
import * as Sentry from "@sentry/react"
import {
  IconLock,
  IconMessageChatbot,
  IconPhoneOutgoing,
} from "@tabler/icons-react"
import { useMutation } from "@tanstack/react-query"
import dayjs from "dayjs"
import { Link, useNavigate, useParams } from "react-router-dom"
import { useAmplitudeTrack } from "../utils/tracking"

export const MedicalRecordPage = () => {
  const { medicalRecordId } = useParams()
  if (!medicalRecordId) {
    throw new Error("No medical medicalRecord id provided")
  }

  const medicalRecordPageDataQuery =
    useMedicalRecordPageDataQuery(medicalRecordId)

  return (
    <QueryWrapper query={medicalRecordPageDataQuery}>
      {({ data }) => <_MedicalRecordPage medicalRecord={data} />}
    </QueryWrapper>
  )
}

interface Props {
  medicalRecord: MedicalRecordResponses.MedicalRecordPageData
}

const _MedicalRecordPage = ({ medicalRecord }: Props) => {
  const navigate = useNavigate()
  const { track } = useAmplitudeTrack()

  const { mutate: createCheckoutSession, isPending: isPaymentPending } =
    useMutation({
      mutationFn: async () => {
        logger.info("Creating checkout session", {
          recordCode: medicalRecord.code,
        })
        const response = await apiClient.post<{ url: string }>(
          "/stripe/create-checkout-session",
          {
            medicalRecordCode: medicalRecord.code,
            vertical: "GENERAL",
          },
        )
        return response.data
      },
      onSuccess: (data) => {
        logger.info("Redirecting to checkout", {
          recordCode: medicalRecord.code,
          url: data.url,
        })
        window.location.href = data.url
      },
      onError: (error) => {
        logger.error("Error creating checkout session", {
          recordCode: medicalRecord.code,
          error,
        })
        Sentry.captureException(error, {
          extra: { recordCode: medicalRecord.code },
        })
      },
    })

  const getTimelineItems = (status: MedicalRecordStatus): TimelineItem[] => {
    if (medicalRecord.isFreemium) {
      const labResultsStep = {
        disabled: !medicalRecord.hasPayment,
        title: "Votre suivi médical",
        subtitle: !medicalRecord.hasPayment
          ? "Le suivi médical par les médecins Bleu est facturé 25€"
          : "Envoyez un fichier en clair ou indiquez-nous le mot de passe",
        content: !medicalRecord.hasPayment ? (
          <Button
            size="md"
            colorScheme="blue"
            leftIcon={<IconLock size={16} />}
            isLoading={isPaymentPending}
            onClick={() => {
              track("PAYMENT_START", {
                recordCode: medicalRecord.code,
                source: "timeline",
              })
              createCheckoutSession()
            }}
          >
            Payer maintenant
          </Button>
        ) : (
          <Stack>
            {medicalRecord.documents
              .filter((doc) => doc.type === "LAB_RESULTS")
              .map((doc) => (
                <DownloadDocumentButton
                  key={doc.id}
                  doc={doc}
                  isDisabled={false}
                />
              ))}
            <UploadDocumentButton medicalRecord={medicalRecord} />
          </Stack>
        ),
      }

      return [
        {
          disabled: false,
          title: "Réception de vos réponses",
          subtitle: "Vos réponses sont en cours d'analyse par un médecin Bleu.",
        },
        {
          disabled: isMedicalRecordStatusBefore(
            status,
            MedicalRecordStatuses.PENDING_PRESCRIPTION,
          ),
          title:
            medicalRecord.documents.length > 0
              ? "Vos ordonnances sont prêtes"
              : "Votre ordonnance est prête",
          content: (
            <Stack>
              {medicalRecord.documents
                .filter((doc) => doc.type.includes("PRESCRIPTION"))
                .map((doc) => (
                  <DownloadDocumentButton
                    key={doc.id}
                    doc={doc}
                    isDisabled={isMedicalRecordStatusBefore(
                      status,
                      MedicalRecordStatuses.PENDING_PRESCRIPTION,
                    )}
                  />
                ))}
            </Stack>
          ),
        },
        labResultsStep,
        {
          disabled: isMedicalRecordStatusBefore(
            status,
            MedicalRecordStatuses.PENDING_LAB_RESULTS_ANALYSIS,
            true,
          ),
          title: "Votre analyse par l'équipe médicale Bleu",
          subtitle:
            status === MedicalRecordStatuses.PENDING_LAB_RESULTS_ANALYSIS
              ? "Votre analyse sera bientôt disponible dans la messagerie sécurisée"
              : "Votre analyse est disponible dans la messagerie sécurisée",
        },
      ]
    }

    return [
      {
        disabled: false,
        title: "Réception de vos réponses",
        subtitle: "Vos réponses sont en cours d'analyse par un médecin Bleu.",
      },
      {
        disabled: false,
        title: "Echangez avec un médecin Bleu",
        subtitle: medicalRecord.phoneCall
          ? medicalRecord.phoneCall.isCallDone
            ? `Votre rendez-vous a eu lieu le ${dayjs(medicalRecord.phoneCall.date).format("LL [à] HH[h]mm")}`
            : `Vous avez rendez-vous le ${dayjs(medicalRecord.phoneCall.date).format("LL [à] HH[h]mm")}`
          : "Vous pouvez planifier un appel ou modifier votre rendez-vous existant",
        content: medicalRecord.phoneCall ? (
          medicalRecord.phoneCall.isCallDone ? undefined : (
            <Button
              size="lg"
              leftIcon={<IconPhoneOutgoing />}
              variant="outline"
              as="a"
              href={medicalRecord.phoneCall.rescheduleUrl}
              onClick={() => {
                track("CALENDLY_START", {
                  recordCode: medicalRecord.code,
                })
              }}
              target="_blank"
            >
              Décaler mon rendez-vous
            </Button>
          )
        ) : (
          <CalendlyButton medicalRecordCode={medicalRecord.code} />
        ),
      },
      {
        disabled: isMedicalRecordStatusBefore(
          status,
          MedicalRecordStatuses.PENDING_PRESCRIPTION,
        ),
        title:
          medicalRecord.documents.length > 0
            ? "Vos ordonnances sont prêtes"
            : "Votre ordonnance est prête",
        content: (
          <Stack>
            {medicalRecord.documents
              .filter((doc) => doc.type.includes("PRESCRIPTION"))
              .map((doc) => (
                <DownloadDocumentButton
                  key={doc.id}
                  doc={doc}
                  isDisabled={isMedicalRecordStatusBefore(
                    status,
                    MedicalRecordStatuses.PENDING_PRESCRIPTION,
                  )}
                />
              ))}
          </Stack>
        ),
      },
      {
        disabled: isMedicalRecordStatusBefore(
          status,
          MedicalRecordStatuses.PENDING_LAB_RESULTS,
          true,
        ),
        title: "Envoyez vos résultats",
        subtitle:
          "Envoyez un fichier en clair ou indiquez-nous le mot de passe",
        content: (
          <Stack>
            {medicalRecord.documents
              .filter((doc) => doc.type === "LAB_RESULTS")
              .map((doc) => (
                <DownloadDocumentButton
                  key={doc.id}
                  doc={doc}
                  isDisabled={isMedicalRecordStatusBefore(
                    status,
                    MedicalRecordStatuses.PENDING_PRESCRIPTION,
                  )}
                />
              ))}
            <UploadDocumentButton medicalRecord={medicalRecord} />
          </Stack>
        ),
      },
      {
        disabled: isMedicalRecordStatusBefore(
          status,
          MedicalRecordStatuses.PENDING_LAB_RESULTS_ANALYSIS,
          true,
        ),
        title: "Votre analyse par l'équipe médicale Bleu",
        subtitle:
          status === MedicalRecordStatuses.PENDING_LAB_RESULTS_ANALYSIS
            ? "Votre analyse sera bientôt disponible dans la messagerie sécurisée"
            : "Votre analyse est disponible dans la messagerie sécurisée",
      },
    ]
  }

  const timelineItems = getTimelineItems(medicalRecord.status)

  return (
    <>
      <PatientHomeLayout.Header onBack={() => navigate("/")} />
      <VSpacer size={4} />
      <HStack justifyContent="space-between" width="100%">
        <Text color="navy.300">N°{medicalRecord.code}</Text>
        <Button
          as={Link}
          to="answers"
          textTransform="none"
          variant="link"
          size="sm"
          color="blue.400"
        >
          Mes réponses aux questions
        </Button>
      </HStack>
      <VSpacer size={5} />
      <Divider borderColor="gray.300" marginX={-20} />
      <VSpacer size={6} />
      <HStack id="test" alignItems="start" gap={8}>
        <VStack alignItems="stretch">
          {medicalRecord.score2 && <Score2Card score2={medicalRecord.score2} />}
          <Timeline items={timelineItems} flex={1} />
        </VStack>
        <VStack
          h="min-content"
          flex={2}
          display={["none", "flex"]}
          className="h-full"
        >
          {medicalRecord.conversationId ? (
            <ConversationProvider conversationId={medicalRecord.conversationId}>
              {({ conversation, sealdSession }) => (
                <ConversationChat
                  isDisabled={
                    medicalRecord.status === MedicalRecordStatuses.CLOSED
                  }
                  conversation={conversation}
                  sealdSession={sealdSession}
                />
              )}
            </ConversationProvider>
          ) : (
            <PlaceholderChatInterface>
              <VStack gap={4} justifyContent="center" h="100%">
                <Text width="50%" textAlign="center" fontWeight="bold">
                  {medicalRecord.isFreemium && !medicalRecord.hasPayment
                    ? "La messagerie sécurisée est accessible uniquement dans le cadre du suivi médical"
                    : "Un médecin Bleu va bientôt vous contacter pour discuter de votre dossier"}
                </Text>
              </VStack>
            </PlaceholderChatInterface>
          )}
        </VStack>
      </HStack>
      <VStack
        display={["flex", "none"]}
        alignItems="stretch"
        position="sticky"
        bottom={10}
      >
        <VSpacer size={20} />
        <Button
          as={Link}
          to="conversation"
          variant="outline"
          leftIcon={
            <Box position="relative">
              <IconMessageChatbot />
              {medicalRecord.hasNewMessage && <RedDot />}
            </Box>
          }
        >
          Accès à la messagerie
        </Button>
      </VStack>
    </>
  )
}
