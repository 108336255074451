import {
  Box,
  Button,
  Heading,
  HStack,
  Input,
  Select,
  Stack,
  Tag,
  TagCloseButton,
  TagLabel,
  useDisclosure,
} from "@chakra-ui/react"
import { BlobProvider, PDFViewer } from "@react-pdf/renderer"
import * as Sentry from "@sentry/react"
import { IconPlus } from "@tabler/icons-react"
import dayjs from "dayjs"
import _ from "lodash"
import { useEffect, useMemo, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { PatientResponses } from "@bleu/types/endpoints/patients"
import {
  ALL_PRESCRIPTIONS_BY_TYPE,
  computeAllPrescriptionsByType,
} from "@bleu/shared/prescriptions/index"
import {
  Prescription,
  PRESCRIPTION_TYPES,
  PrescriptionType,
} from "@bleu/shared/prescriptions/types"
import { useDoctorInfo } from "@bleu/front/components/auth/DoctorContext"
import { useUser } from "@bleu/front/components/auth/UserContext"
import { PrescriptionModal } from "@bleu/front/components/doctor/PrescriptionModal"
import { PrescriptionPreview } from "@bleu/front/components/doctor/PrescriptionPreview"
import { BackButton } from "@bleu/front/components/layout/BackButton"
import { DoctorLayout } from "@bleu/front/components/layout/DoctorLayout"
import { useUploadPrescriptionMutation } from "@bleu/front/queries/medicalRecords"

type ChipProps = {
  label: string
  isSelected: boolean
  onClick: () => void
  onRemove: () => void
}

const PrescriptionChip = ({
  label,
  isSelected,
  onClick,
  onRemove,
}: ChipProps) => {
  return (
    <Tag
      borderWidth={1}
      borderColor="blue.400"
      bg={isSelected ? "blue.400" : "blue.200"}
      color={isSelected ? "white" : "blue.400"}
      _hover={{ bg: "blue.300" }}
      cursor="pointer"
      onClick={!isSelected ? onClick : onRemove}
    >
      <TagLabel>{label}</TagLabel>
      {isSelected && <TagCloseButton onClick={onRemove} />}
    </Tag>
  )
}

type Props = {
  medicalRecord: PatientResponses.PatientMedicalRecord
}

export const PrescriptionEditor = ({ medicalRecord }: Props) => {
  const doctor = useUser()
  const doctorInfo = useDoctorInfo()

  const {
    patientId,
    recordId,
    prescriptionType: initialPrescriptionType,
  } = useParams()

  const navigate = useNavigate()
  const { isOpen, onOpen, onClose } = useDisclosure()

  const [isPrescriptionReady, setIsPrescriptionReady] = useState(false)
  const [prescriptionType, setPrescriptionType] = useState<PrescriptionType>(
    initialPrescriptionType
      ? (initialPrescriptionType as PrescriptionType)
      : PrescriptionType.PRESCRIPTION_TREATMENT,
  )
  const [filename, setFilename] = useState(
    `${initialPrescriptionType ? PRESCRIPTION_TYPES[prescriptionType] : "Ordonnance"} du ${dayjs().format("DD-MM-YYYY")}`,
  )
  const [customPrescriptions, setCustomPrescriptions] = useState<
    Prescription[]
  >([])
  const [prescribedItems, setPrescribedItems] = useState<Prescription[]>([])

  const prescriptions = useMemo(() => {
    const prescriptionsByType = computeAllPrescriptionsByType(medicalRecord)
    const currentTypePrescriptions = prescriptionsByType[prescriptionType] ?? []
    const allTypePrescriptions = ALL_PRESCRIPTIONS_BY_TYPE[prescriptionType]

    return allTypePrescriptions.map((prescription) => {
      const isSuggested = currentTypePrescriptions.find(
        (p) => p.shortName == prescription.shortName,
      )
      return { ...prescription, isSuggested }
    })
  }, [medicalRecord, prescriptionType])

  useEffect(() => {
    if (initialPrescriptionType && !isPrescriptionReady) {
      setPrescribedItems(prescriptions.filter((p) => p.isSuggested))
      setIsPrescriptionReady(true)
    }
  }, [
    initialPrescriptionType,
    isPrescriptionReady,
    prescribedItems.length,
    prescriptions,
  ])

  const {
    mutateAsync: uploadPrescription,
    isPending: isUploadingPrescription,
  } = useUploadPrescriptionMutation(medicalRecord.id)

  const handleSavePDF = async (blob: Blob | null) => {
    if (!blob) {
      Sentry.captureMessage("No blob on prescription upload", {
        extra: { medicalRecordId: medicalRecord.id },
      })
      return // TODO: send visual notification to alert doctor
    }
    await uploadPrescription({
      blob,
      patientId: medicalRecord.patientId,
      doctorId: doctor.id,
      prescriptionType,
      filename: `${filename}.pdf`,
    })
    navigate(`/doctor/patients/${patientId}/records/${recordId}`)
  }

  const handleAddPrescription = (prescription: Prescription) => {
    if (!prescribedItems.includes(prescription)) {
      setPrescribedItems((currentPrescriptions) => [
        ...currentPrescriptions,
        prescription,
      ])
    }
  }

  const handleAddCustomPrescription = (prescription: Prescription) => {
    setCustomPrescriptions((currentPrescriptions) => [
      ...currentPrescriptions,
      prescription,
    ])
    handleAddPrescription(prescription)
  }

  const handleRemovePrescription = (prescription: Prescription) => {
    setPrescribedItems((currentPrescriptions) =>
      currentPrescriptions.filter((p) => p.title !== prescription.title),
    )
  }

  return (
    <Stack gap={12} w="100%" h="100%">
      <HStack gap={6}>
        <BackButton
          onClick={() =>
            navigate(
              `/doctor/patients/${medicalRecord.patientId}/records/${medicalRecord.id}`,
            )
          }
        />
        <DoctorLayout.Title>
          {medicalRecord.patient.fullName}
        </DoctorLayout.Title>
      </HStack>
      <HStack gap={5} align="flex-start" h="100%">
        <Box w="60%" h="calc(100vh - 224px)">
          <PDFViewer
            style={{
              width: "100%",
              height: "100%",
              borderRadius: 8,
            }}
          >
            <PrescriptionPreview
              prescriptionType={prescriptionType}
              prescriptions={prescribedItems}
              patient={medicalRecord.patient}
              patientAge={medicalRecord.profile.age}
              doctor={doctor}
              doctorInfo={doctorInfo}
            />
          </PDFViewer>
        </Box>
        <Box w="40%">
          <DoctorLayout.Body>
            <Stack gap={5}>
              <Heading>Editer une ordonnance</Heading>
              <Select
                style={{
                  cursor: initialPrescriptionType ? "not-allowed" : "inherit",
                  pointerEvents: initialPrescriptionType ? "none" : "inherit",
                }}
                value={prescriptionType}
                onChange={(e) =>
                  setPrescriptionType(e.target.value as PrescriptionType)
                }
              >
                {_.map(PRESCRIPTION_TYPES, (label, value) => (
                  <option key={value} value={value}>
                    {label}
                  </option>
                ))}
              </Select>
              <Input
                value={filename}
                onChange={(e) => setFilename(e.target.value)}
              />
              <Stack gap={4}>
                <Heading size="md">Prescriptions par défaut</Heading>
                <HStack wrap="wrap">
                  {prescriptions.map((prescription) => (
                    <PrescriptionChip
                      key={prescription.shortName}
                      label={prescription.shortName}
                      onClick={() => handleAddPrescription(prescription)}
                      onRemove={() => handleRemovePrescription(prescription)}
                      isSelected={prescribedItems.includes(prescription)}
                    />
                  ))}
                </HStack>
              </Stack>
              <Stack gap={4}>
                <HStack justify="space-between">
                  <Heading size="md">Prescriptions personnalisées</Heading>
                  <Button
                    size="xs"
                    variant="outline"
                    leftIcon={<IconPlus />}
                    onClick={onOpen}
                  >
                    Ajouter
                  </Button>
                </HStack>
                <PrescriptionModal
                  isOpen={isOpen}
                  onClose={onClose}
                  onSave={handleAddCustomPrescription}
                />
                <HStack wrap="wrap">
                  {customPrescriptions.map((prescription) => (
                    <PrescriptionChip
                      key={prescription.shortName}
                      label={prescription.shortName}
                      onClick={() => handleAddPrescription(prescription)}
                      onRemove={() => handleRemovePrescription(prescription)}
                      isSelected={prescribedItems.includes(prescription)}
                    />
                  ))}
                </HStack>
              </Stack>

              <BlobProvider
                document={
                  <PrescriptionPreview
                    prescriptionType={prescriptionType}
                    prescriptions={prescribedItems}
                    patient={medicalRecord.patient}
                    patientAge={medicalRecord.profile.age}
                    doctor={doctor}
                    doctorInfo={doctorInfo}
                  />
                }
              >
                {({ blob }) => (
                  <Button
                    mt={10}
                    onClick={() => handleSavePDF(blob)}
                    isLoading={isUploadingPrescription}
                  >
                    Envoyer l&apos;ordonnance
                  </Button>
                )}
              </BlobProvider>
            </Stack>
          </DoctorLayout.Body>
        </Box>
      </HStack>
    </Stack>
  )
}
