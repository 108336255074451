import { ErrorBoundaryFallback } from "@bleu/front/ErrorBoundaryFallback"
import { colors } from "@bleu/front/assets/theme/colors"
import { CalendlyButton } from "@bleu/front/components/CalendlyButton"
import { Logo } from "@bleu/front/components/layout/Logo"
import { Spacer } from "@bleu/front/components/layout/Spacer"
import {
  useDepistagesStore,
  useFunnelStore,
  useQuestionnaireStore,
  useVerticalsStore,
} from "@bleu/front/lib/stores"
import { useUTMStore } from "@bleu/front/lib/stores/utmStore"
import { logger } from "@bleu/front/utils/logger"
import { gtmTrack, useAmplitudeTrack } from "@bleu/front/utils/tracking"
import { Blue } from "@bleu/shared/utils/Blue"
import {
  Button,
  Center,
  Link as ChakraLink,
  Heading,
  Stack,
  Text,
  VStack,
} from "@chakra-ui/react"
import { IconCircleCheckFilled } from "@tabler/icons-react"
import { usePostHog } from "posthog-js/react"
import { useEffect } from "react"
import ReactPixel from "react-facebook-pixel"
import { Link, useNavigate, useSearchParams } from "react-router-dom"
import { useUser } from "../../auth/UserContext"

export const PaymentSuccess = () => {
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const user = useUser()
  const posthog = usePostHog()
  const { utmSource, utmMedium, utmCampaign, utmContent, clearUTMParams } =
    useUTMStore()
  const { track } = useAmplitudeTrack()

  const sessionId = searchParams.get("session_id")
  const recordCode = searchParams.get("r")
  const priceStr = searchParams.get("p")

  const isFreemium = searchParams.get("isFreemium") === "true"

  logger.info("Payment success", { sessionId, recordCode, priceStr })

  useEffect(() => {
    if (sessionId) {
      logger.info("Payment successful, tracking event", { sessionId })
      const price = priceStr ? parseFloat(priceStr) / 100 : 0

      // Get UTM params from store
      const utmParams = {
        utm_source: utmSource,
        utm_medium: utmMedium,
        utm_campaign: utmCampaign,
        utm_content: utmContent,
      }

      // Only include non-null UTM params in tracking
      const cleanUtmParams = Object.fromEntries(
        Object.entries(utmParams).filter(([_, value]) => value !== null),
      )

      if (process.env.NODE_ENV === "development") {
        logger.info("UTM Parameters at payment success:", cleanUtmParams)
      }

      // Track events with UTM params and conversion data
      track("PAYMENT_SUCCESS", {
        price,
        recordCode,
        sessionId,
        conversion_id: 11300802771,
        conversion_label: "7W6cCOHZ_sIZENOh0owq",
        ...cleanUtmParams,
      })

      // Track Meta Pixel event
      ReactPixel.track("PaymentSuccess", {
        value: price,
        currency: "EUR",
        content_ids: [recordCode],
        conversion_id: 11300802771,
        conversion_label: "7W6cCOHZ_sIZENOh0owq",
        ...(user && { user_email: user.emailAddress }),
        ...cleanUtmParams,
      })

      // Track Google Ads conversion with updated parameters
      if (typeof window !== "undefined" && window.gtag) {
        window.gtag("event", "conversion", {
          send_to: "AW-11300802771/nW47CNe0xusZENOh0owq",
          value: price,
          currency: "EUR",
          transaction_id: sessionId,
          conversion_id: 11300802771,
          conversion_label: "7W6cCOHZ_sIZENOh0owq",
        })
      }

      // Only clear UTM params after successful tracking
      clearUTMParams()

      // Reset the funnel store
      useFunnelStore.getState().reset()
      useQuestionnaireStore.getState().reset()
      useVerticalsStore.getState().reset()
      useDepistagesStore.getState().reset()
    } else {
      logger.warn("No session_id found in search params")
    }

    // Redirect to home page after 30 seconds
    const timer = setTimeout(() => {
      logger.info("Redirecting to home page")
      navigate("/")
    }, 30000)

    return () => clearTimeout(timer)
  }, [
    sessionId,
    recordCode,
    priceStr,
    navigate,
    user,
    posthog,
    utmSource,
    utmMedium,
    utmCampaign,
    utmContent,
    clearUTMParams,
    track,
  ])

  if (!recordCode) {
    logger.error("No record code found in search params")
    return (
      <ErrorBoundaryFallback
        error={new Error("No record code found in search params")}
        resetErrorBoundary={() => {}}
      />
    )
  }

  return (
    <VStack alignItems="stretch" gap={0} maxW={560} mx="auto">
      <Center>
        <IconCircleCheckFilled color={colors.green[400]} size={56} />
      </Center>
      <Spacer size={6} />
      <Heading size="3xl" textAlign="center">
        {isFreemium ? (
          <>
            Votre paiement a bien été <Blue>pris en compte</Blue>
          </>
        ) : (
          <>
            Votre ordonnance <Blue>arrive bientôt</Blue> !
          </>
        )}
      </Heading>
      <Spacer size={8} />
      <Text size="lg" textAlign="center">
        {isFreemium
          ? "Vous pouvez envoyer dès maintenant vos résultats d'analyses depuis votre espace patient."
          : "Vos prescriptions vont être vérifiées par notre équipe médicale"}
      </Text>
      <Spacer size={6} />
      <Text size="lg" textAlign="center" fontWeight={600} color="blue.400">
        Votre n° de dossier est le {recordCode}
      </Text>
      <Spacer size={6} />
      {!isFreemium && (
        <Text size="lg" textAlign="center" fontWeight={600}>
          Votre ordonnance sera disponible dans votre espace sécurisé sous 24h.
        </Text>
      )}
      <Spacer size={6} />
      <Button as={Link} to="/" size="lg">
        Revenir à mon espace
      </Button>
      <Spacer size={10} />
      {!isFreemium && (
        <>
          <Text size="lg" textAlign="center">
            Pour que vos examens soient remboursables, la règlementation demande
            un échange téléphonique avec le médecin. <br />
            Si vous le souhaitez, merci de planifier votre appel. Prévoyez 2 à 5
            minutes selon vos questions éventuelles.
          </Text>
          <Spacer size={6} />
          <CalendlyButton medicalRecordCode={recordCode} />
          <Spacer size={14} />
        </>
      )}
      <Text size="sm" textAlign="center">
        Merci d&apos;avoir utilisé
      </Text>
      <Spacer size={4} />
      <Logo />
      <Spacer size={6} />
      <Stack>
        <Text size="sm" textAlign="center">
          Nous restons à votre disposition pour vous accompagner dans vos
          besoins de prévention et pour toute question non médicale{" "}
          <ChakraLink href="mailto:contact@bleu.care">
            contact@bleu.care
          </ChakraLink>
        </Text>
        <Text size="sm" textAlign="center">
          Nous vous rappelons que les frais de service de Bleu.care ne sont pas
          remboursés par l&apos;assurance maladie
        </Text>
      </Stack>
    </VStack>
  )
}
